import * as React from "react";
import { Card } from "react-bootstrap";

interface Props {
    label: string;
    total: number;
    children: any;
}

const GenericCard = ({ label, total, children }: Props) => {
    return (
        <Card>
            <Card.Body>
                <h3>{total}</h3>
                <p className="text-muted">{label}</p>
                {children}
            </Card.Body>
        </Card>
    )
}

export default GenericCard;