import { AnyAction } from "redux";
import { _fetch } from "../../../services/sessionService";
import { AppThunk } from "../../store";
import { counterActions, CounterAction } from "../counter/counter-logic";
import { run, TaskEnum } from "../task/tasks-logic";
import { templatesActions } from "../templates/templates-logic";
import { userActions } from "../user/user-logic";

export const appActions = {
    init: (): AppThunk<Promise<void>> => async (dispatch, getState) => {
        await run({type: TaskEnum.initAppPrimary, dispatch, getState}, async () => {
            // fetching data...
            await dispatch(templatesActions.loadTemplates());
        });

        await run({type: TaskEnum.initAppSecondary, dispatch, getState}, async () => {
            await dispatch(userActions.initSessionService());
            dispatch(counterActions.hit(CounterAction.OpenApp)); // not awaiting
        });
    },
}