import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createDefaultConfig } from "../../../services/config-editable-presets";
import { createBindingContextHandler, removeConfigItem } from "./config-editor-store-helpers";

export enum GameEdition {
    CSGO = 1,
    CS2 = 2,
}

export type ConfigItem = ActionItem | PurchaseItem | SettingItem | ExtraItem;

export enum ConfigItemType {
    Action = 'action',
    Setting = 'setting',
    Purchase = 'purchase',
    Extra = 'extra',
}

export interface AnyConfigItem {
    type: ConfigItemType;
}

export interface ActionItem extends AnyConfigItem {
    type: ConfigItemType.Action;
    cmd: string;
}

export interface PurchaseItem extends AnyConfigItem {
    type: ConfigItemType.Purchase;
    items: string[];
}

export type SettingItem = SliderSetting | DropdownSetting | TextboxSetting;

export enum SettingType {
    Slider = 'slider',
    Dropdown = 'dropdown',
    Textbox = 'textbox',
}

export interface AnySettingItem extends AnyConfigItem {
    type: ConfigItemType.Setting;
    settingType: SettingType;
    cmd: string;
}

export interface SliderSetting extends AnySettingItem {
    settingType: SettingType.Slider;
    value: number | number[];
}

export interface DropdownSetting extends AnySettingItem {
    settingType: SettingType.Dropdown;
    value: string | number | number[];
}

export interface TextboxSetting extends AnySettingItem {
    settingType: SettingType.Textbox;
    value: string | number | number[];
}

export type ExtraItem = ExecuteCmdExtraItem | LoopCommandsExtraItem | VolumeExtraItem;

export enum ExtraItemType {
    ExecuteCmd = 'executeCmd',
    LoopCommands = 'loopCommands',
    Volume = 'volume',
}

export const isAction = (item: ConfigItem): item is ActionItem => item.type === ConfigItemType.Action;
export const isPurchase = (item: ConfigItem): item is PurchaseItem => item.type === ConfigItemType.Purchase;
export const isSetting = (item: ConfigItem): item is SettingItem => item.type === ConfigItemType.Setting;
export const isExtra = (item: ConfigItem): item is ExtraItem => item.type === ConfigItemType.Extra;
export const isExecuteCmdExtra = (item: ExtraItem): item is ExecuteCmdExtraItem => item.extraType === ExtraItemType.ExecuteCmd;
export const isLoopCommandsExtra = (item: ExtraItem): item is LoopCommandsExtraItem => item.extraType === ExtraItemType.LoopCommands;
export const isVolumeExtra = (item: ExtraItem): item is VolumeExtraItem => item.extraType === ExtraItemType.Volume;

interface AnyExtraItem {
    type: ConfigItemType.Extra;
    extraType: ExtraItemType;
}

export interface ExecuteCmdExtraItem extends AnyExtraItem {
    extraType: ExtraItemType.ExecuteCmd;
    commands: string[];
}

export interface LoopCommandsExtraItem extends AnyExtraItem {
    extraType: ExtraItemType.LoopCommands;
    loop: string[];
}

export enum VolumeMode {
    Increase = 'increase',
    Decrease = 'decrease'
}

export interface VolumeExtraItem extends AnyExtraItem {
    extraType: ExtraItemType.Volume;
    mode: VolumeMode;
}

export interface VolumePayload {
    min: number;
    max: number;
    step: number;
}


export type BindingContext = KeyboardBindingContext | OneTimeBindingContext | CustomAliasBindingContext;

export enum BindingContextType {
    Keyboard,
    OneTime,
    CustomAlias
}

interface AnyBindingContext {
    type: BindingContextType;
}

interface KeyboardBindingContext extends AnyBindingContext {
    type: BindingContextType.Keyboard;
    isKeyDown: boolean;
    keys: string[];
}

interface OneTimeBindingContext extends AnyBindingContext {
    type: BindingContextType.OneTime;
}

interface CustomAliasBindingContext extends AnyBindingContext {
    type: BindingContextType.CustomAlias;
    alias?: string;
}

interface DependencyStore {
    volume?: VolumePayload;
}

export interface Search {
    value: string;
    exact: boolean;
}

export interface BindItem {
    up: ConfigItem[];
    down: ConfigItem[];
}

export interface Config {
    bind: Record<string, BindItem>;
    alias: Record<string, ConfigItem[]>;
    oneTime: ConfigItem[];
    store: DependencyStore;
}

export enum ConfigTab {
    Actions = "actions",
    Purchase = "purchase",
    GameSettings = "gamesettings",
    Extra = "extra",
}

export interface StoredConfig {
    id: string;
    name: string;
    configJson: string;
    hash: string;
}

export interface ShortLink {
    id: string;
    name: string;
    configNames: string[];
}

export interface ConfigEditorState {
    config: Config;
    editor: {
        binding: BindingContext;
        tab: ConfigTab;
        search: Search;
        cloudConfigId: string | null;
        storedConfigs: StoredConfig[];
        shortLinks: ShortLink[];
    };
}

export interface BindingContextItemsActions {
    getItems: () => ConfigItem[];
    setItems: (items: ConfigItem[]) => Config;
}

// --------- action payload ---------
interface BindingContextPayload {
    bindingContext: BindingContext;
}

interface ItemPayload extends BindingContextPayload {
    item: ConfigItem;
}

interface MoveItemPayload extends BindingContextPayload {
    index: number;
    direction: MoveDirection;
}
// ----------------------------------

export enum MoveDirection {
    Left = "left",
    Right = "right"
}

const initialState: ConfigEditorState = {
    config: createDefaultConfig(),
    editor: {
        binding: {
            type: BindingContextType.Keyboard,
            isKeyDown: true,
            keys: []
        },
        tab: ConfigTab.Actions,
        search: {
            value: '',
            exact: false
        },
        cloudConfigId: null,
        storedConfigs: [],
        shortLinks: [],
    },
}

const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        toggleItem: (state, action: PayloadAction<ItemPayload>) => {
            const payload = action.payload;
            const actions = createBindingContextHandler(state.config, payload.bindingContext);
            const items = actions.getItems();
            const filtered = removeConfigItem(payload.item, items);
            if (filtered.length === items.length) {
                filtered.push(payload.item);
            }
            state.config = actions.setItems(filtered);
        },
        updateItem: (state, action: PayloadAction<ItemPayload>) => {
            const payload = action.payload;
            const actions = createBindingContextHandler(state.config, payload.bindingContext);
            const items = actions.getItems();
            const filtered = removeConfigItem(payload.item, items);
            filtered.push(payload.item);
            state.config = actions.setItems(filtered);
        },
        moveItem: (state, action: PayloadAction<MoveItemPayload>) => {
            const payload = action.payload;
            const actions = createBindingContextHandler(state.config, payload.bindingContext);
            const items = [...actions.getItems()];
            const targetItem = items.splice(payload.index, 1)[0];
            const newIndex = payload.direction === "left" ? payload.index - 1 : payload.index + 1;
            items.splice(newIndex, 0, targetItem);
            state.config = actions.setItems(items);
        },
        updateBindingContext: (state, action: PayloadAction<BindingContext>) => {
            state.editor.binding = action.payload;
        },
        setConfigTab: (state, action: PayloadAction<ConfigTab>) => {
            state.editor.tab = action.payload;
        },
        addAlias: (state, action: PayloadAction<string>) => {
            state.config.alias[action.payload] = [];
        },
        removeAlias: (state, action: PayloadAction<string>) => {
            delete state.config.alias[action.payload];
        },
        loadConfig: (state, action: PayloadAction<Config>) => {
            state.config = action.payload;
        },
        updateVolumePayload: (state, action: PayloadAction<VolumePayload>) => {
            state.config.store.volume = action.payload;
        },
        updateSearch: (state, action: PayloadAction<Search>) => {
            state.editor.search = action.payload;
        },
        updateCloudConfigId: (state, action: PayloadAction<string | null>) => {
            state.editor.cloudConfigId = action.payload;
        },
        receiveStoredConfigs: (state, action: PayloadAction<StoredConfig[]>) => {
            state.editor.storedConfigs = action.payload;
        },
        receiveStoredConfig: (state, { payload: config }: PayloadAction<StoredConfig>) => {
            state.editor.storedConfigs = [
                ...state.editor.storedConfigs.filter(c => c.id !== config.id),
                config
            ];
        },
        receiveShortLinks: (state, action: PayloadAction<ShortLink[]>) => {
            state.editor.shortLinks = action.payload;
        },
    }
});

export const {
    toggleItem, updateItem, moveItem, updateBindingContext, setConfigTab, addAlias, removeAlias, loadConfig,
    updateVolumePayload, updateSearch, updateCloudConfigId, receiveStoredConfigs, receiveStoredConfig, receiveShortLinks,
} = configSlice.actions;
export default configSlice.reducer;