import { useCallback } from "react";
import { configEditorActions } from "../store/features/config-editor/config-editor-logic";
import { getBindingContext } from "../store/features/config-editor/config-editor-selectors";
import { BindingContext } from "../store/features/config-editor/config-editor-slice";
import { useAppDispatch, useAppSelector } from "../store/store"

type UseBindingContextResult = [
    BindingContext,
    (ctx: BindingContext) => void
]

const useBindingContext = (): UseBindingContextResult => {
    const dispatch = useAppDispatch();
    const bindingContext = useAppSelector(state => getBindingContext(state));

    const handleChange = useCallback((ctx: BindingContext) => {
        dispatch(configEditorActions.setBindingContext(ctx));
    }, [dispatch]);
    
    return [
        bindingContext,
        handleChange,
    ]    
}

export default useBindingContext;