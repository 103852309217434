import * as React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import './../../../assets/scss/style.scss';

import Breadcrumb from '../../../App/layout/AdminLayout/Breadcrumb';
import logoDark from '../../../assets/images/logo-dark.png';
import { useState } from 'react';
import { useAppDispatch } from '../../../store/store';
import { userActions } from '../../../store/features/user/user-logic';
import { routes } from '../../../routes';
const SignUp1 = () => {

    const [name, setName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [remember, setRemember] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const dispatch = useAppDispatch();
    const history = useHistory();

    const handleSubmit = async () => {
        try {
            await dispatch(userActions.login(name, password, remember));
            history.push(routes.cloudConfigs);
        }
        catch (e) {
            setError(e.toString());
        }
    }
    
    return (
        <>
            <Breadcrumb />
            <div className="auth-wrapper">
                <div className="auth-content">
                    <div className="card">
                        <div className="row align-items-center text-center">
                            <div className="col-md-12">
                                <div className="card-body">
                                    {/* <img src={logoDark} alt="" className="img-fluid mb-4" /> */}
                                    <h4 className="mb-3 f-w-400">Signin</h4>
                                    {error &&
                                        <span className='text-danger'>
                                            {error}
                                        </span>
                                    }
                                    <div className="form-group fill">
                                        <input
                                            value={name} onChange={e => setName(e.target.value)}
                                            type="text" className="form-control" id="email" placeholder="User name"
                                        />
                                    </div>
                                    <div className="form-group fill mb-4">
                                        <input
                                            value={password} onChange={e => setPassword(e.target.value)}
                                            type="password" className="form-control" id="password" placeholder="Password"
                                        />
                                    </div>

                                    <Form.Group className="text-left">
                                        <Form.Check
                                            checked={remember} onChange={e => setRemember(e.target.checked)}
                                            custom type="checkbox" id="supported-checkbox" label={'Save credentials'} />
                                    </Form.Group>

                                    <button className="btn btn-block btn-primary mb-4" onClick={handleSubmit}>Signin</button>
                                    {/* <p className="mb-2 text-muted">
                                        Forgot password?{' '}
                                        <NavLink to="/auth/reset-password-1" className="f-w-400">
                                            Reset
                                        </NavLink>
                                    </p>
                                    <p className="mb-0 text-muted">
                                        Don’t have an account?{' '}
                                        <NavLink to="/auth/signup-1" className="f-w-400">
                                            Signup
                                        </NavLink>
                                    </p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default SignUp1;
