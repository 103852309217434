import { BindingContext, BindingContextItemsActions, BindingContextType, BindItem, Config, ConfigItem, ConfigItemType } from "./config-editor-slice";
import { encodeSequence } from "../../../utils/keySequenceUtils";

export function createBindingContextHandler(state: Config, bindingContext: BindingContext): BindingContextItemsActions {

    if (bindingContext.type === BindingContextType.Keyboard && !bindingContext.keys.length)
        throw new Error('Not empty key sequence was expected');
    if (bindingContext.type === BindingContextType.CustomAlias && !bindingContext.alias)
        throw new Error('Alias name was expected');

    switch (bindingContext.type) {
        case BindingContextType.Keyboard: {
            let key = encodeSequence(bindingContext.keys);
            const keyStateKey: keyof BindItem = bindingContext.isKeyDown ? "down" : "up";
            return {
                getItems: () => {
                    const bind: BindItem = state.bind[key] ?? {down: [], up: []};
                    return bind[keyStateKey] ?? [];
                },
                setItems: (items) => {
                    const binds = {...state.bind};
                    let bindItem = binds[key] ?? {down: [], up: []};
                    bindItem = {
                        ...bindItem,
                        [keyStateKey]: items
                    }
                    binds[key] = bindItem;
                    if (!bindItem.down.length && !bindItem.up.length) {
                        delete binds[key];
                    }
                    return {
                        ...state,
                        bind: binds
                    }
                }
            };
        }
        case BindingContextType.OneTime: {
            return {
                getItems: () => state.oneTime,
                setItems: (items) => ({
                    ...state,
                    oneTime: items
                })
            }
        }
        case BindingContextType.CustomAlias: {
            const alias = bindingContext.alias;
            if (!alias) {
                throw new Error('Alias name was expected');
            }
            return {
                getItems: () => state.alias[alias],
                setItems: (items) => ({
                    ...state,
                    alias: {
                        ...state.alias,
                        [alias]: items
                    }
                })
            }
        }
        default: {
            const typeguard: never = bindingContext;
            throw new Error(`Unknown binding context: ${typeguard}`);
        }
    }
}

export function createBindingContextHandlerOrNull(state: Config, bindingContext: BindingContext): BindingContextItemsActions | null {
    try {
        return createBindingContextHandler(state, bindingContext);
    }
    catch {
        return null;
    }
}

export function removeConfigItem(item: ConfigItem, items: ConfigItem[]): ConfigItem[] {
    switch (item.type) {
        case ConfigItemType.Action: {
            return items.filter(i => i.type !== ConfigItemType.Action || i.cmd !== item.cmd);
        }
        case ConfigItemType.Purchase: {
            return items.filter(i => i.type !== ConfigItemType.Purchase);
        }
        case ConfigItemType.Setting: {
            return items.filter(i => i.type !== ConfigItemType.Setting || i.cmd !== item.cmd);
        }
        case ConfigItemType.Extra: {
            return items.filter(i => i.type !== ConfigItemType.Extra || i.extraType !== item.extraType);
        }
        default: {
            const typeguard: never = item;
            throw new Error(`Unknown config item: ${typeguard}`);
        }
    }
}