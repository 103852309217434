import { _API_ } from "../consts";
import deviceId from "../utils/deviceId";
import objectToUrlQuery from "../utils/objectToUrlQuery";

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

type RefreshUrlProvider = (refreshToken: string) => string;
type PayloadHandler = (payload: Record<string, string> | null) => void;

export interface TokenResult {
    accessToken: string;
    refreshToken: string;
}

interface TokenStorage extends TokenResult {
    isPersistent: boolean;
}

class SessionService {

    private refreshUrlProvider: RefreshUrlProvider | undefined;
    private payloadHandler: PayloadHandler | undefined;

    private tokenRefresher: Promise<void> | null = null;

    public async initialize(refreshUrlProvider: RefreshUrlProvider, payloadHandler: PayloadHandler) {
        this.refreshUrlProvider = refreshUrlProvider;
        this.payloadHandler = payloadHandler;

        if (!this.getStorage()?.isPersistent) {
            this.setStorage(null);
        }

        const accessToken = await this.refreshTokenIfExpired();
        if (accessToken) {
            const payload = this.extractPayload(accessToken);
            payloadHandler(payload);
        }
    }

    public smartFetch = async <T>(url: string, method: HttpMethod, data?: any): Promise<T> => {
        const dataToUrl = typeof data === 'object' && method === 'GET';

        if (!url.startsWith('http')) {
            url = `${_API_}/${url}`;
        }

        let _url: string = encodeURI(url);
        let body: RequestInit['body'] = undefined;
        let headers: RequestInit['headers'] = {
            'Device-Id': deviceId
        };

        if (data instanceof FormData) {
            body = data;
        }
        else if (method === 'GET') {
            _url = url + (dataToUrl ? `?${objectToUrlQuery(data)}` : '');
        }
        else {
            body = !dataToUrl ? JSON.stringify(data) : undefined;
            headers["Content-Type"] = "application/json; charset=utf-8";
        }

        const token = await this.refreshTokenIfExpired();
        token && (headers["Authorization"] = `Bearer ${token}`);

        const response = await fetch(_url, {
            method,
            body,
            headers
        });

        if (response.status === 204) {
            return undefined as any;
        }

        const result = await response.json();

        if (response.ok) {
            return result as T;
        }
        else {
            const error = result as ErrorResponse;
            const inner = error.innerErrorMessage ? `. Inner error: ${error.innerErrorMessage}` : ``;
            throw `${error.errorMessage} ${inner}`;
        }
    }

    public storeTokens(result: TokenResult | null, isPersistent: boolean = false) {
        if (result) {
            const payload = this.extractPayload(result.accessToken);
            this.payloadHandler && this.payloadHandler(payload);
            this.setStorage({
                ...result,
                isPersistent
            })
        }
        else {
            this.payloadHandler && this.payloadHandler(null);
            this.setStorage(null);
        }
    }

    private async refreshTokenIfExpired(): Promise<string | null> {
        const storage = this.getStorage();
        if (!storage) {
            return null;
        }

        const accessToken = storage.accessToken;
        const payload = this.extractPayload(accessToken);
        const isExpired = new Date().getTime() > (payload.exp * 1000);

        if (isExpired) {
            if (!this.tokenRefresher) {
                this.tokenRefresher = this.refreshToken(storage);
            }
            await this.tokenRefresher;
            this.tokenRefresher = null;
        }
        return this.getAccessToken();
    }

    private async refreshToken(storage: TokenStorage): Promise<void> {
        if (!this.refreshUrlProvider)
            return;

        const { refreshToken, isPersistent } = storage;
        const refreshUrl = this.refreshUrlProvider(refreshToken);

        const response = await fetch(`${_API_}/${refreshUrl}`, {
            headers: {
                'Device-Id': deviceId
            }
        });

        if (response.ok) {
            const result = await response.json();
            this.storeTokens(result, isPersistent);
        }
        else {
            this.setStorage(null);
        }
    }

    private extractPayload(accessToken: string): Record<string, any> {
        return JSON.parse(atob(accessToken.split('.')[1]));
    }

    private getAccessToken(): string | null {
        const storage = this.getStorage();
        return storage ? storage?.accessToken : null;
    }

    private getStorage(): TokenStorage | null {
        const json = localStorage.getItem('token');
        return json ? JSON.parse(json) : null;
    }

    private setStorage(storage: TokenStorage | null) {
        if (storage) {
            const json = JSON.stringify(storage);
            localStorage.setItem('token', json);
        }
        else {
            localStorage.removeItem('token');
        }
    }
}

export interface ErrorResponse {
    errorMessage: string;
    innerErrorMessage: string | null;
}

const sessionService = new SessionService();

export async function _fetch<T>(url: string, method: HttpMethod, data?: any): Promise<T> {
    return await sessionService.smartFetch(url, method, data);
}
export default sessionService;