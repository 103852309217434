import { createSelector } from 'reselect';
import { localizeAction } from '../../../locale/localization';
import { createSorter } from '../../../utils/createSorter';
import { ApplicationState } from '../../store';
import { getSearch } from '../config-editor/config-editor-selectors';
import { ActionCategory, SettingsCategory, SettingTemplate } from './templates-slice';

export const getTemplates = (state: ApplicationState) => state.templates;

export const getActionCategories = createSelector(
    [getTemplates, getSearch],
    (templates, search) => {
        const sorter = createSorter<ActionCategory>(c => c.items.length, 'desc');
        const searchLower = search.value.toLowerCase();
        const categories = templates.actions;
        const filtered: ActionCategory[] = [];

        for (const category of categories) {
            const items = category.items.filter(i => (
                search.exact ? 
                    i.cmd.toLowerCase() === searchLower :
                    (i.cmd.toLowerCase().includes(searchLower) || localizeAction(i.cmd).toLowerCase().includes(searchLower))
            ));
            if (items.length) {
                filtered.push({
                    name: category.name,
                    items: items
                })
            }
        }
        return filtered.sort(sorter);
    }
);

export const getPurchaseCategories = createSelector(
    [getTemplates],
    templates => templates.purchases
);

const sorter = createSorter<SettingTemplate>(t => t.cmd);

export const getSettingsCategories = createSelector(
    [getTemplates, getSearch],
    (templates, search) => {
        const searchLower = search.value.toLowerCase();
        const categories = templates.settings;
        const filtered: SettingsCategory[] = [];

        for (const category of categories) {
            const items = [...category.items].sort(sorter).filter(i => 
                search.exact ?
                    i.cmd.toLowerCase() === searchLower :
                    i.cmd.toLowerCase().includes(searchLower));
            if (items.length) {
                filtered.push({
                    name: category.name,
                    items
                })
            }
        }
        return filtered;
    }
)