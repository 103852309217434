
export type Executable = string | AliasCmd | BindCmd;

export enum ExecutableType {
    AliasCmd = "alias",
    BindCmd = "bind"
}

interface AnyExecutable {
    type: ExecutableType;
}

export interface AliasCmd extends AnyExecutable {
    type: ExecutableType.AliasCmd;
    name: string;
    commands: Executable[];
}

export interface BindCmd extends AnyExecutable {
    type: ExecutableType.BindCmd;
    key: string;
    commands: Executable[];
}

export function alias(name: string, commands: Executable[]): AliasCmd {
    return {
        type: ExecutableType.AliasCmd,
        name,
        commands
    }
}

export function bind(key: string, commands: Executable[]): BindCmd {
    return {
        type: ExecutableType.BindCmd,
        key,
        commands
    }
}