import React, { useCallback, useContext, useMemo, useState } from "react";
import Loader from "../App/layout/Loader";

const context = React.createContext<ContextProps | null>(null);

export type ContextProps = (fun: Starter) => Promise<void>;

type Starter = (() => Promise<void>) | Promise<void>;

export const LoadingBarProvider: React.FC = ({children}) => {

    const [count, setCount] = useState<number>(0);

    const startLoading = useCallback(async (starter: Starter) => {
        setCount((v) => v + 1);
        try {
            if (typeof starter === 'function') {
                await starter();
            }
            else {
                await starter;
            }
        }
        finally {
            setCount((v) => v - 1);
        }
    }, []);
    
    const isLoading = useMemo<boolean>(() => count > 0, [count]);

    return (
        <context.Provider value={startLoading}>
            {isLoading && <Loader/>}
            {children}
        </context.Provider>
    )
}

export const useLoadingBar = (): ContextProps => {
    return useContext(context)!;
}