import * as React from "react";
import { Col, Row } from "react-bootstrap";

import Chart from 'react-apexcharts';
import { useLoadingBar } from "../../../hooks/useLoadingBar";
import { useEffect, useMemo, useState } from "react";
import { _fetch } from "../../../services/sessionService";
import SeriesCard from "./common/SeriesCard";
import StatsCard from "./common/StatsCard";
import GenericCard from "./common/GenericCard";

type ChartProps = React.ComponentProps<typeof Chart>;

interface Stats {
    deviceIdCount: number;
    ipCount: number;
    deviceIdSeries: number[];
    ipSeries: number[];
    totalUsers: number;
    totalConfigs: number;
    totalLinks: number;
    totalAnonLinks: number;
    userAgents: string[];
}

const emptyStats: Stats = {
    deviceIdCount: 0,
    ipCount: 0,
    deviceIdSeries: [],
    ipSeries: [],
    totalUsers: 0,
    totalConfigs: 0,
    totalLinks: 0,
    totalAnonLinks: 0,
    userAgents: [],
}

const AdminDashboard = () => {

    const startLoading = useLoadingBar();
    const [stats, setStats] = useState<Stats>(emptyStats);

    useEffect(() => {
        startLoading(async () => {
            const result = await _fetch<Stats>('api/admin/stats', 'GET');
            setStats(result);
        });
    }, []);
    
    return (<>
        <Row>
            <Col xs={12} md={3}>
                <StatsCard
                    icon='users'
                    label='Users'
                    total={stats.totalUsers}
                    color='green'
                />
            </Col>
            <Col xs={12} md={3}>
                <StatsCard
                    icon='sliders'
                    label='Configs'
                    total={stats.totalConfigs}
                    color='yellow'
                />
            </Col>
            <Col xs={12} md={3}>
                <StatsCard
                    icon='link'
                    label='Persistent links'
                    total={stats.totalLinks}
                    color='red'
                />
            </Col>
            <Col xs={12} md={3}>
                <StatsCard
                    icon='link'
                    label='Anonymous links'
                    total={stats.totalAnonLinks}
                    color='red'
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6}>
                <SeriesCard
                    label="Unique devices" tooltip="Unique devices:" 
                    total={stats.deviceIdCount} series={stats.deviceIdSeries}
                />
            </Col>
            <Col xs={12} md={6}>
                <SeriesCard
                    label="Unique IPs" tooltip="Unique IPs:" 
                    total={stats.ipCount} series={stats.ipSeries}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={12}>
                <GenericCard total={stats.userAgents.length} label="User agents">
                    {stats.userAgents.map(agent => <>
                        <span key={agent}>{agent}</span><br/>
                    </>)}
                </GenericCard>
            </Col>
        </Row>
    </>)
}

export default AdminDashboard;