import { localizeAction } from "../../../locale/localization";
import { isArrayOfNumber } from "../../../utils/types";
import { AppThunk } from "../../store";
import { DropdownSettingTemplate } from "../templates/templates-slice";
import { getBindingContext } from "./config-editor-selectors";
import { addAlias, BindingContext, BindingContextType, Config, ConfigItem, ConfigItemType, ConfigTab, ExtraItemType, loadConfig, MoveDirection, moveItem, removeAlias, setConfigTab, toggleItem, updateBindingContext, updateCloudConfigId, updateItem, updateSearch, updateVolumePayload, VolumePayload } from "./config-editor-slice";

export function getConfigItemName(item: ConfigItem) {
    switch (item.type) {
        case ConfigItemType.Action: {
            return localizeAction(item.cmd);
        }
        case ConfigItemType.Purchase: {
            return 'Buy scenario';
        }
        case ConfigItemType.Setting: {
            return item.cmd;
        }
        case ConfigItemType.Extra: {
            switch (item.extraType) {
                case ExtraItemType.ExecuteCmd: {
                    return 'Execute command';
                }
                case ExtraItemType.LoopCommands: {
                    return 'Loop commands';
                }
                case ExtraItemType.Volume: {
                    return 'Volume regulator';
                }
                default: {
                    const typeguard: never = item;
                    throw new Error(`Unknown extra item: ${JSON.stringify(item)}`);
                }
            }
        }
        default: {
            const typeguard: never = item;
            throw new Error(`Unknown config item: ${typeguard}`);
        }
    }
}

export function getDropdownSettingItemNames(template: DropdownSettingTemplate) {
    const names: Record<string | number, string> = {};
    const options = template.options;
    if (isArrayOfNumber(options) && options.length === 2 && options.includes(0) && options.includes(1)) {
        names[0] = 'Off';
        names[1] = 'On';
    }
    else {
        const displayNames = template.displayNames ?? {};

        for (const option of options) {
            names[option] = displayNames[option] ?? option.toString();
        }
    }
    return names;
}

interface ItemFocusParams {
    tab: ConfigTab;
    search?: string;
}

export function getItemFocusParams(item: ConfigItem): ItemFocusParams {
    switch (item.type) {
        case ConfigItemType.Action: {
            return {
                tab: ConfigTab.Actions,
                search: item.cmd
            }
        }
        case ConfigItemType.Purchase: {
            return {
                tab: ConfigTab.Purchase,
            }
        }
        case ConfigItemType.Setting: {
            return {
                tab: ConfigTab.GameSettings,
                search: item.cmd
            }
        }
        case ConfigItemType.Extra: {
            return {
                tab: ConfigTab.Extra,
            }
        }
        default: {
            const typeguard: never = item;
            throw new Error(`Unknown item type: ${JSON.stringify(item)}`);
        }
    }
}

export function parseConfig(configJson: string): Config {
    const config = JSON.parse(configJson);
    return config;
}

export const configEditorActions = {
    setBindingContext: (bindingContext: BindingContext): AppThunk<void> => (dispatch, getState) => {
        dispatch(updateBindingContext(bindingContext));
    },
    toggleItem: (item: ConfigItem): AppThunk => (dispatch, getState) => {
        const bindingContext = getBindingContext(getState());
        dispatch(toggleItem({item, bindingContext}));
    },
    updateItem: (item: ConfigItem): AppThunk => (dispatch, getState) => {
        const bindingContext = getBindingContext(getState());
        dispatch(updateItem({item, bindingContext}));
    },
    moveItem: (index: number, direction: MoveDirection): AppThunk => (dispatch, getState) => {
        const bindingContext = getBindingContext(getState());
        dispatch(moveItem({index, direction, bindingContext}));
    },
    addAlias: (alias: string): AppThunk => (dispatch, getState) => {
        dispatch(addAlias(alias));
    },
    removeAlias: (alias: string): AppThunk => (dispatch, getState) => {
        dispatch(removeAlias(alias));
    },
    setCurrentConfig: (configJson: string, cloudConfigId: string | null): AppThunk => (dispatch, getState) => {
        dispatch(updateBindingContext({
            type: BindingContextType.Keyboard,
            keys: [],
            isKeyDown: true
        }));
        dispatch(loadConfig(parseConfig(configJson)));
        dispatch(updateCloudConfigId(cloudConfigId));
    },
    updateVolumePayload: (volumePayload: VolumePayload): AppThunk => (dispatch, getState) => {
        dispatch(updateVolumePayload(volumePayload));
    },
    updateSearch: (search: string): AppThunk => (dispatch, getState) => {
        dispatch(updateSearch({value: search, exact: false}));
    },
    focusItem: (item: ConfigItem): AppThunk => (dispatch, getState) => {
        const {tab, search} = getItemFocusParams(item);
        dispatch(setConfigTab(tab));
        dispatch(updateSearch({value: search ?? "", exact: true}));
    },
    setTab: (tab: ConfigTab): AppThunk => (dispatch, getState) => {
        dispatch(setConfigTab(tab));
        dispatch(updateSearch({value: '', exact: false}));
    },
}