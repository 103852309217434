import React, { useState, useMemo } from "react";
import { Dropdown } from "react-bootstrap";

interface OwnProps {
    options: SelectOption[];
    value: string;
    onChange: (value: string) => void;
    disabled?: boolean;
    className?: string;
}

export interface SelectOption {
    value: string;
    label: string;
}

const DropdownSelector = ({options, value, onChange, disabled=false, className}: OwnProps) => {
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

    const label = useMemo<string>(() => {
        return options.find(o => o.value === value)?.label ?? '<No value>';
    }, [options, value]);

    const toggle = () => {
        setDropdownOpen(!dropdownOpen);
    }

    return (
        <Dropdown className={className} show={dropdownOpen} onToggle={toggle}>
            <Dropdown.Toggle variant='outline-primary' size='sm' disabled={disabled}>
                {label}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {options.map(o => (
                    <Dropdown.Item key={o.value} onClick={() => onChange(o.value)}>
                        {o.label}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default DropdownSelector;