import * as React from "react";
import { Button, Card, Form, Table } from "react-bootstrap";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { getShortLinks, getStoredConfigs } from "../../../store/features/config-editor/config-editor-selectors";
import { ShortLink } from "../../../store/features/config-editor/config-editor-slice";
import { configManagementActions } from "../../../store/features/config-management/config-management-logic";
import Select from "react-select";
import { useMemo, useState } from "react";
import { getIsStoredConfigListLoaded } from "../../../store/features/config-management/config-management-selectors";
import { _BASE_URL_ } from "../../../consts";
import { withErrorAlert } from "../../../utils/withErrorAlert";
import { routes } from "../../../routes";

const CloudConfigs = () => {

    const [editedLink, setEditedLink] = useState<ShortLink | null>(null);

    const configs = useAppSelector(getStoredConfigs);
    const shortLinks = useAppSelector(getShortLinks);
    const isConfigsLoaded = useAppSelector(getIsStoredConfigListLoaded);

    const dispatch = useAppDispatch();

    const handleDelete = async (l: ShortLink) => {
        await dispatch(configManagementActions.deleteShortLink(l));
    }

    const handleAdd = async () => {
        await withErrorAlert(async (mySwal) => {
            const result = await mySwal.fire<string>({
                text: 'Link name:',
                input: 'text'
            });

            if (!result.value) {
                throw 'Invalid name';
            }
    
            await dispatch(configManagementActions.createShortLink(result.value, []));
        });
    }

    const options = useMemo(() => {
        return configs.map(c => ({
            value: c.name,
            label: c.name,
            color: '#00B8D9'
        }))
    }, [configs]);

    const handleInputKeydown = async (e: any) => {
        if (e.key === 'Enter' && editedLink) {
            await withErrorAlert(async () => {
                await dispatch(configManagementActions.updateShortLink(editedLink));
                setEditedLink(null);
            });
        }
    }

    return (
        <Card className="table-card">
            <Card.Header className='d-flex justify-content-between'>
                <Card.Title as="h5">Short links</Card.Title>
                <Button size='sm' variant='outline-primary' onClick={handleAdd}>
                    Add new
                </Button>
            </Card.Header>
            <Card.Body className="pb-0">
                <div style={{ height: '465px' }}>
                    <PerfectScrollbar>
                        <Table hover>
                            <thead>
                                <tr>
                                    <th style={{ width: '35%' }}>Name</th>
                                    <th style={{ width: '40%' }}>Configs</th>
                                    <th style={{ width: '5%' }}></th>
                                    <th style={{ width: '20%' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {isConfigsLoaded && shortLinks.map(l => {
                                    const url = `${_BASE_URL_}${routes.shortLink(l.name)}`;
                                    return (
                                        <tr key={l.id}>
                                            <td>
                                                {editedLink?.id === l.id ?
                                                    <Form.Control
                                                        type='text' size='sm' style={{ width: `${editedLink.name + 2}ch` }}
                                                        value={editedLink.name}
                                                        onChange={e => setEditedLink({ ...editedLink, name: e.target.value })}
                                                        onKeyDown={handleInputKeydown}
                                                    /> :
                                                    <>
                                                        <a href={url}>
                                                            {url}
                                                        </a>
                                                        <i className="feather icon-copy ml-1 text-muted" role='button' onClick={() => navigator.clipboard.writeText(url)} />
                                                    </>
                                                }
                                            </td>
                                            <td>
                                                {editedLink?.id === l.id ?
                                                    <Select
                                                        value={options.filter(o => editedLink.configNames.includes(o.value))}
                                                        onChange={(v) => setEditedLink({...editedLink, configNames: v.map(o => o.value)})}
                                                        isMulti
                                                        name="colors"
                                                        options={options}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        onKeyDown={handleInputKeydown}
                                                    /> :
                                                    <span role='button' onClick={() => setEditedLink(l)}>
                                                        {l.configNames.join(', ')}
                                                    </span>
                                                }
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                                <i className="icon feather icon-edit f-18 text-c-green" role='button' onClick={() => setEditedLink(l)}/>
                                                <i className="feather icon-trash-2 f-16 ml-3 text-c-red" role='button' onClick={() => handleDelete(l)} />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </PerfectScrollbar>
                </div>
            </Card.Body>
        </Card>
    )
}

export default CloudConfigs;