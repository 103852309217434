import React from "react";
import { Card, Col, Form, Tab, Tabs } from "react-bootstrap";
import { configEditorActions } from "../../../store/features/config-editor/config-editor-logic";
import { getConfigTab, getSearch } from "../../../store/features/config-editor/config-editor-selectors";
import { ConfigTab } from "../../../store/features/config-editor/config-editor-slice";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import ActionsTab from "./ActionsTab";
import ExtraTab from "./ExtraTab";
import PurchaseTab from "./PurchaseTab";
import SettingsTab from "./SettingsTab";

interface Props {
}

const Library = ({}: Props) => {

    const dispatch = useAppDispatch();

    const search = useAppSelector(state => getSearch(state).value);
    const tab = useAppSelector(state => getConfigTab(state));

    const handleTab = (value: ConfigTab | null) => {
        if (value) {
            dispatch(configEditorActions.setTab(value));
        }
    }

    const handleSearch = (v: string) => {
        dispatch(configEditorActions.updateSearch(v));
    }

    return (
        <Col xs={12}>
            <Card>
                <Card.Header className='d-flex justify-content-between flex-wrap align-items-center'>
                    <Card.Text className={`text-primary m-b-0 f-20 f-w-600`} role='button'>
                        Actions
                    </Card.Text>
                    <div>
                        <Form.Group className='m-b-0'>
                            <Form.Control type="text" placeholder="Search" value={search} onChange={e => handleSearch(e.target.value)}/>
                            <div className="form-icon" role='button' onClick={() => handleSearch('')}>
                                <i className="feather icon-x" />
                            </div>
                        </Form.Group>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Tabs defaultActiveKey={ConfigTab.Actions} className="mb-3" activeKey={tab} onSelect={t => handleTab(t as ConfigTab | null)}>
                        <Tab eventKey={ConfigTab.Actions} title="ACTIONS">
                            {tab === ConfigTab.Actions && <ActionsTab/>}
                        </Tab>
                        <Tab eventKey={ConfigTab.Purchase} title="BUY SCENARIO">
                            {tab === ConfigTab.Purchase && <PurchaseTab/>}
                        </Tab>
                        <Tab eventKey={ConfigTab.GameSettings} title="GAME SETTINGS">
                            {tab === ConfigTab.GameSettings && <SettingsTab/>}
                        </Tab>
                        <Tab eventKey={ConfigTab.Extra} title="EXTRA">
                            {tab === ConfigTab.Extra && <ExtraTab/>}
                        </Tab>
                    </Tabs>
                </Card.Body>
            </Card>
        </Col>
    )
}

export default Library;