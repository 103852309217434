import React from "react"
import { Col, Form, Row } from "react-bootstrap"
import { configEditorActions } from "../../../store/features/config-editor/config-editor-logic"
import { getIsValidBindingContext } from "../../../store/features/config-editor/config-editor-selectors"
import { VolumePayload, VolumeExtraItem, VolumeMode } from "../../../store/features/config-editor/config-editor-slice"
import { useAppDispatch, useAppSelector } from "../../../store/store"
import DropdownSelector, { SelectOption } from "./DropdownSelector"
import ExtraContainer from "./ExtraContainer"

interface Props {
    volumePayload: VolumePayload;
    item: VolumeExtraItem;
    isSelected: boolean;
}

const options: SelectOption[] = [
    { label: 'Increase', value: VolumeMode.Increase },
    { label: 'Decrease', value: VolumeMode.Decrease },
]

const VolumeExtra = ({ volumePayload, item, isSelected }: Props) => {

    const dispatch = useAppDispatch()

    const isValidBinding = useAppSelector(state => getIsValidBindingContext(state));

    const handlePayload = (prop: keyof VolumePayload, e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        let payload = {
            ...volumePayload,
            [prop]: parseFloat(value.toFixed(2))
        }
        const delta = payload.max - payload.min;
        const step = payload.step <= delta ? payload.step : delta;
        payload.step = parseFloat(step.toFixed(2));
        dispatch(configEditorActions.updateVolumePayload(payload));
    }

    const handleMode = (value: string) => {
        dispatch(configEditorActions.updateVolumePayload(volumePayload));
        dispatch(configEditorActions.updateItem({
            ...item,
            mode: value as VolumeMode
        }));
    }

    return (
        <ExtraContainer item={item} isSelected={isSelected}>
            <Row>
                <Col xs={12} md={4}>
                    Min: {volumePayload.min}
                    <Form.Control
                        type='range' value={volumePayload.min} disabled={!isValidBinding} onInput={(e: any) => handlePayload('min', e)}
                        step={0.01} min={0} max={volumePayload.max - 0.01}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={4}>
                    Max: {volumePayload.max}
                    <Form.Control
                        type='range' value={volumePayload.max} disabled={!isValidBinding} onInput={(e: any) => handlePayload('max', e)}
                        step={0.01} min={volumePayload.min + 0.01} max={1}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={4}>
                    Step: {volumePayload.step}
                    <Form.Control
                        type='range' value={volumePayload.step} disabled={!isValidBinding} onInput={(e: any) => handlePayload('step', e)}
                        step={0.01} min={0.01} max={volumePayload.max - volumePayload.min}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={4}>
                    Action
                    <DropdownSelector
                        options={options}
                        value={item.mode}
                        onChange={handleMode}
                        disabled={!isValidBinding}
                    />
                </Col>
            </Row>
        </ExtraContainer>
    );
}

export default VolumeExtra;