const english: Record<string, string> = {
    action_slot1: "Primary weapon",
    action_slot2: "Secondary weapon",
    action_slot3: "Knife",
    action_slot4: "Grenades",
    action_slot5: "Bomb",
    action_slot6: "HE grenade",
    action_slot7: "Flashbang",
    action_slot8: "Smoke grenade",
    action_slot9: "Decoy",
    action_slot10: "Molotov",
    action_slot11: "Zeus",
    action_slot12: "Medi-shot",
    action_invprev: "Previous weapon",
    action_invnext: "Next weapon",
    action_lastinv: "Last weapon",
    action_cl_show_team_equipment: "Show team equipment",
    action_quickinv: "Radial weapon menu",
    action_buymenu: "Buy menu",
    action_autobuy: "Auto buy",
    action_rebuy: "Rebuy",
    action_attack: "Fire",
    action_attack2: "Secondary fire",
    action_reload: "Reload",
    action_drop: "Drop",
    action_use: "Use",
    action_showscores: "Scoreboard",
    action_forward: "Forward",
    action_back: "Back",
    action_moveleft: "Move left",
    action_moveright: "Move right",
    action_left: "Move left",
    action_right: "Move right",
    action_jump: "Jump",
    action_duck: "Crouch",
    action_speed: "Walk",
    action_sprint: "Walk",
    action_voicerecord: "Microphone",
    action_radio1: "Command radio",
    action_radio2: "Standard radio",
    action_radio3: "Report radio",
    action_radialradio: "Radial radio 1",
    action_radialradio2: "Radial radio 2",
    action_radialradio3: "Radial radio 3",
    action_messagemode: "Global chat",
    action_messagemode2: "Team chat",
    action_player_ping: "Player ping",
    action_god: "God",
    action_noclip: "Noclip",
    action_mp_warmup_start: "Start warmup",
    action_mp_warmup_end: "End warmup",
    action_mp_swapteams: "Swap teams",
    action_endround: "End round",
    action_bot_add_t: "Add bot [T]",
    action_bot_add_ct: "Add bot [CT]",
    action_bot_place: "Place bot",
    action_bot_kick: "Kick bots",
    action_sv_rethrow_last_grenade: "Rethrow last grenade",
    action_lookatweapon: "Inspect weapon",
    action_spray_menu: "Graffiti menu",
    action_unbindall: "Unbind all",
    action_r_cleardecals: "Clear decals",
    action_callvote: "Call vote",
    action_teammenu: "Choose team",
    action_toggleconsole: "Console",
    action_clear: "Clear console",
    action_cl_clearhinthistory: "Clear hint history",
    action_screenshot: "Screenshot",
    action_pause: "Pause",
    action_disconnect: "Disconnect",
    action_quit: "Quit game",
    action_demo_resume: "Demo resume",
    action_demo_togglepause: "Toggle demo pause",
    action_jump_throw: "Jumpthrow",
    action_display_damage_on: "Display damage ON",
    action_display_damage_off: "Display damage OFF",
    action_fast_bomb_drop: "Fast bomb drop",

    buyitem_glock: "Glock | USP",
    buyitem_elite: "Dual berettas",
    buyitem_p250: "P250",
    buyitem_tec9: "CZ75 | Fiveseven | Tec9",
    buyitem_deagle: "Deagle",
    buyitem_nova: "Nova",
    buyitem_xm1014: "XM1014",
    buyitem_mag7: "Sawed-Off | Mag7",
    buyitem_m247: "M247",
    buyitem_negev: "Negev",
    buyitem_mac10: "Mac10 | MP9",
    buyitem_mp7: "MP7",
    buyitem_ump45: "UMP45",
    buyitem_p90: "P90",
    buyitem_bizon: "PP-Bizon",
    buyitem_galilar: "Galil | Famas",
    buyitem_ak47: "AK47 | M4",
    buyitem_ssg08: "SSG08",
    buyitem_sg556: "SG553 | Aug",
    buyitem_awp: "AWP",
    buyitem_g3sg1: "G3SG1 | Scar",
    buyitem_vest: "Vest",
    buyitem_vesthelm: "Vest + helmet",
    buyitem_taser: "Zeus",
    buyitem_defuser: "Defuse kits",
    buyitem_molotov: "Molotov",
    buyitem_decoy: "Decoy",
    buyitem_flashbang: "Flashbang 1",
    buyitem_flashbang2: "Flashbang 2",
    buyitem_hegrenade: "HE",
    buyitem_smokegrenade: "Smoke",

    tip_extra_execute_cmd: "Use this if you did not find the action/setting you want. All commands you specify here will be inlined directly to config",

    'virtual_key_esc': 'Esc',
    'virtual_key_f1': 'F1',
    'virtual_key_f2': 'F2',
    'virtual_key_f3': 'F3',
    'virtual_key_f4': 'F4',
    'virtual_key_f5': 'F5',
    'virtual_key_f6': 'F6',
    'virtual_key_f7': 'F7',
    'virtual_key_f8': 'F8',
    'virtual_key_f9': 'F9',
    'virtual_key_f10': 'F10',
    'virtual_key_f11': 'F11',
    'virtual_key_f12': 'F12',
    'virtual_key_prt': 'Prt',
    'virtual_key_scrolllock': 'Scr',
    'virtual_key_pause': 'Pause',
    'virtual_key_`': '`',
    'virtual_key_1': '1',
    'virtual_key_2': '2',
    'virtual_key_3': '3',
    'virtual_key_4': '4',
    'virtual_key_5': '5',
    'virtual_key_6': '6',
    'virtual_key_7': '7',
    'virtual_key_8': '8',
    'virtual_key_9': '9',
    'virtual_key_0': '0',
    'virtual_key_-': '-',
    'virtual_key_=': '=',
    'virtual_key_backspace': 'Backspace',
    'virtual_key_ins': 'Ins',
    'virtual_key_home': 'Home',
    'virtual_key_pgpp': 'PgUp',
    'virtual_key_numlock': 'Num',
    'virtual_key_kp_slash': '/',
    'virtual_key_kp_divide': '/',
    'virtual_key_kp_multiply': '*',
    'virtual_key_kp_minus': '-',
    'virtual_key_tab': 'Tab',
    'virtual_key_q': 'Q',
    'virtual_key_w': 'W',
    'virtual_key_e': 'E',
    'virtual_key_r': 'R',
    'virtual_key_t': 'T',
    'virtual_key_y': 'Y',
    'virtual_key_u': 'U',
    'virtual_key_i': 'I',
    'virtual_key_o': 'O',
    'virtual_key_p': 'P',
    'virtual_key_[': '[',
    'virtual_key_]': ']',
    'virtual_key_\\': '\\',
    'virtual_key_del': 'Del',
    'virtual_key_end': 'End',
    'virtual_key_pgdn': 'PgDn',
    'virtual_key_kp_home': '7',
    'virtual_key_kp_7': '7',
    'virtual_key_kp_uparrow': '8', 
    'virtual_key_kp_8': '8', 
    'virtual_key_kp_pgup': '9',
    'virtual_key_kp_9': '9',
    'virtual_key_kp_plus': '+',
    'virtual_key_capsLock': 'CapsLock',
    'virtual_key_a': 'A',
    'virtual_key_s': 'S',
    'virtual_key_d': 'D',
    'virtual_key_f': 'F',
    'virtual_key_g': 'G',
    'virtual_key_h': 'H',
    'virtual_key_j': 'J',
    'virtual_key_k': 'K',
    'virtual_key_l': 'L',
    'virtual_key_;': ';',
    'virtual_key_\'': '\'',
    'virtual_key_enter': 'Enter',
    'virtual_key_kp_leftarrow': '4',
    'virtual_key_kp_4': '4',
    'virtual_key_kp_5': '5',
    'virtual_key_kp_rightarrow': '6',
    'virtual_key_kp_6': '6',
    'virtual_key_shift': 'Shift',
    'virtual_key_z': 'Z',
    'virtual_key_x': 'X',
    'virtual_key_c': 'C',
    'virtual_key_v': 'V',
    'virtual_key_b': 'B',
    'virtual_key_n': 'N',
    'virtual_key_m': 'M',
    'virtual_key_,': ',',
    'virtual_key_.': '.',
    'virtual_key_/': '/',
    'virtual_key_rshift': 'Shift',
    'virtual_key_uparrow': 'Up',
    'virtual_key_kp_end': '1',
    'virtual_key_kp_1': '1',
    'virtual_key_kp_downarrow': '2',
    'virtual_key_kp_2': '2',
    'virtual_key_kp_pgdn': '3',
    'virtual_key_kp_3': '3',
    'virtual_key_kp_enter': 'Enter',
    'virtual_key_ctrl': 'Ctrl',
    'virtual_key_win': 'Win',
    'virtual_key_alt': 'Alt',
    'virtual_key_space': 'Space',
    'virtual_key_ralt': 'Alt',
    'virtual_key_apps': 'Apps',
    'virtual_key_rctrl': 'Ctrl',
    'virtual_key_leftarrow': 'Left',
    'virtual_key_downarrow': 'Down',
    'virtual_key_rightarrow': 'Right',
    'virtual_key_kp_ins': '0',
    'virtual_key_kp_0': '0',
    'virtual_key_kp_del': '.',
    'virtual_key_mouse4': '',
    'virtual_key_mouse5': '',
    'virtual_key_mouse1': '',
    'virtual_key_mwheelup': '↑',
    'virtual_key_mouse3': '',
    'virtual_key_mwheeldown': '↓',
    'virtual_key_mouse2': '',
};

export default english;