import { Config } from "../store/features/config-editor/config-editor-slice";
import { downloadJson, downloadText } from "../utils/download";
import { ConfigRepository, ConfigResult } from "./config-repository";

const webConfigRepository: ConfigRepository = {
    openConfig: async () => {
        return await new Promise<ConfigResult | null>(resolve => {
            const input = document.createElement("input");
            input.type = "file";
            input.accept = ".cpc";
            input.addEventListener("change", async (e: any) => {
                if (e.target.files?.length) {
                    const file = e.target.files[0];
                    const name = file.name.slice(0, -4);
                    const configJson = await file.text();
                    resolve({ configJson, name });
                }
                else {
                    resolve(null);
                }
            });
            input.click();
        });
    },
    saveConfig: async (name: string, config: Config) => {
        console.log(config);
        const json = JSON.stringify(config, null, 4);
        downloadJson(json, `${name}.cpc`);
    },
    generateConfig: async (name: string, content: string) => {
        console.log(content);
        downloadText(content, `${name}.cfg`);
    }
}

export default webConfigRepository;