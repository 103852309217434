import React, { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { configEditorActions } from '../../../store/features/config-editor/config-editor-logic';
import { SliderSetting } from '../../../store/features/config-editor/config-editor-slice';
import { SliderSettingTemplate } from '../../../store/features/templates/templates-slice';
import { useAppDispatch } from '../../../store/store';

interface SliderControlProps {
    template: SliderSettingTemplate;
    item: SliderSetting;
    disabled?: boolean;
}

const SliderControl = ({template, item, disabled}: SliderControlProps) => {

    const dispatch = useAppDispatch();

    const normalizedValue = useMemo<number>(() => {
        return typeof item.value === 'number' ? item.value : template.default;
    }, [item, template]);

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        dispatch(configEditorActions.updateItem({...item, value}));
    }

    return (<>
            <span>{template.min}</span>
            <Form.Control
                type='range' value={normalizedValue} disabled={disabled} onInput={handleInput} onChange={() => {/* stub */}}
                step={template.step} min={template.min} max={template.max}
            />
            <span>{template.max}</span>
    </>)
}

export default SliderControl;