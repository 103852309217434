import React from "react";
import { Form } from "react-bootstrap";
import { configEditorActions, getConfigItemName } from "../../../store/features/config-editor/config-editor-logic";
import { getIsValidBindingContext } from "../../../store/features/config-editor/config-editor-selectors";
import { ExtraItem } from "../../../store/features/config-editor/config-editor-slice";
import { useAppDispatch, useAppSelector } from "../../../store/store";

interface Props {
    children: any;
    item: ExtraItem;
    isSelected: boolean;
}

const ExtraContainer = ({ children, item, isSelected }: Props) => {

    const dispatch = useAppDispatch();

    const isValidBinding = useAppSelector(state => getIsValidBindingContext(state));

    return (
        <div className='p-15' style={{ border: '1px solid gray', marginBottom: -1 }}>
            <Form.Group>
                <Form.Check
                    custom type="checkbox" id={`extra_${item.extraType}`}
                    disabled={!isValidBinding} label={getConfigItemName(item)}
                    checked={isSelected} onChange={() => dispatch(configEditorActions.toggleItem(item))}
                />
            </Form.Group>
            <Form.Group>
                {children}
            </Form.Group>
        </div>
    );
}

export default ExtraContainer;