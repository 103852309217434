import React, { useMemo } from "react";
import { getExtraItems, getVolumePayload } from "../../../store/features/config-editor/config-editor-selectors";
import { ConfigItemType, LoopCommandsExtraItem, ExecuteCmdExtraItem, ExtraItemType, isLoopCommandsExtra, isExecuteCmdExtra, isVolumeExtra, VolumeExtraItem, VolumeMode, VolumePayload } from "../../../store/features/config-editor/config-editor-slice";
import { getIsDeveloper } from "../../../store/features/user/user-selectors";
import { useAppSelector } from "../../../store/store";
import LoopCommandsExtra from "./LoopCommandsExtra";
import ExecuteCmdExtra from "./ExecuteCmdExtra";
import VolumeExtra from "./VolumeExtra";

interface Props {
}

const ExtraTab = ({}: Props) => {

    const extraItems = useAppSelector(state => getExtraItems(state));
    const volumePayload = useAppSelector(state => getVolumePayload(state));
    const isDeveloper = useAppSelector(state => getIsDeveloper(state));
    
    const existingCmdItem = useMemo<ExecuteCmdExtraItem | undefined>(() => {
        return extraItems.find(isExecuteCmdExtra);
    }, [extraItems]);

    const normalizedExecuteCmdItem = useMemo<ExecuteCmdExtraItem>(() => {
        return {
            type: ConfigItemType.Extra,
            extraType: ExtraItemType.ExecuteCmd,
            commands: existingCmdItem?.commands ?? []
        }
    }, [existingCmdItem]);


    const existingLoopCommandsItem = useMemo<LoopCommandsExtraItem | undefined>(() => {
        return extraItems.find(isLoopCommandsExtra);
    }, [extraItems]);

    const normalizedLoopCommandsItem = useMemo<LoopCommandsExtraItem>(() => {
        return {
            type: ConfigItemType.Extra,
            extraType: ExtraItemType.LoopCommands,
            loop: existingLoopCommandsItem?.loop ?? [],
        }
    }, [existingLoopCommandsItem]);


    const normalizedVolumePayload = useMemo<VolumePayload>(() => {
        return volumePayload ?? {
            min: 0,
            max: 1,
            step: 0.25
        }
    }, [volumePayload]);

    const existingVolumeItem = useMemo<VolumeExtraItem | undefined>(() => {
        return extraItems.find(isVolumeExtra);
    }, [extraItems]);

    const normalizedVolumeItem = useMemo<VolumeExtraItem>(() => {
        return {
            type: ConfigItemType.Extra,
            extraType: ExtraItemType.Volume,
            mode: existingVolumeItem?.mode ?? VolumeMode.Decrease
        }
    }, [existingVolumeItem]);


    return (
        <div>
            <ExecuteCmdExtra item={normalizedExecuteCmdItem} isSelected={!!existingCmdItem}/>
            <LoopCommandsExtra item={normalizedLoopCommandsItem} isSelected={!!existingLoopCommandsItem}/>
            <VolumeExtra volumePayload={normalizedVolumePayload} item={normalizedVolumeItem} isSelected={!!existingVolumeItem}/>
        </div>
    )
}

export default ExtraTab;