import { ExtraItemType, ConfigItemType, VolumeMode, SettingType, Config } from "../store/features/config-editor/config-editor-slice"

export const createEmptyConfig = (): Config => ({
    bind: {},
    alias: {},
    oneTime: [],
    store: {}
});

export const createDefaultConfig = (): Config => ({
    "bind": {
        "1": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "slot1"
                }
            ],
            "up": []
        },
        "2": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "slot2"
                }
            ],
            "up": []
        },
        "3": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "slot3"
                }
            ],
            "up": []
        },
        "4": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "slot4"
                }
            ],
            "up": []
        },
        "5": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "slot5"
                }
            ],
            "up": []
        },
        "w": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "forward"
                }
            ],
            "up": []
        },
        "s": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "back"
                }
            ],
            "up": []
        },
        "a": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "moveleft"
                }
            ],
            "up": []
        },
        "d": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "moveright"
                }
            ],
            "up": []
        },
        "space": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "jump"
                }
            ],
            "up": []
        },
        "shift": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "speed"
                }
            ],
            "up": []
        },
        "ctrl": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "duck"
                }
            ],
            "up": []
        },
        "f": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "lookatweapon"
                }
            ],
            "up": []
        },
        "tab": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "showscores"
                }
            ],
            "up": []
        },
        "e": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "use"
                }
            ],
            "up": []
        },
        "mouse1": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "attack"
                }
            ],
            "up": []
        },
        "mouse2": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "attack2"
                }
            ],
            "up": []
        },
        "g": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "drop"
                }
            ],
            "up": []
        },
        "`": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "toggleconsole"
                }
            ],
            "up": []
        },
        "r": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "reload"
                }
            ],
            "up": []
        },
        "q": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "lastinv"
                }
            ],
            "up": []
        },
        "t": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "spray_menu"
                }
            ],
            "up": []
        },
        "z": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "radio1"
                }
            ],
            "up": []
        },
        "x": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "radio2"
                }
            ],
            "up": []
        },
        "c": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "radio3"
                }
            ],
            "up": []
        },
        "b": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "buymenu"
                }
            ],
            "up": []
        },
        "m": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "teammenu"
                }
            ],
            "up": []
        },
        "y": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "messagemode"
                }
            ],
            "up": []
        },
        "u": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "messagemode2"
                }
            ],
            "up": []
        },
        "mwheelup": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "invnext"
                }
            ],
            "up": []
        },
        "mwheeldown": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "invprev"
                }
            ],
            "up": []
        },
        "kp_ins": {
            "down": [
                {
                    "type": ConfigItemType.Purchase,
                    "items": [
                        "vesthelm",
                        "vest"
                    ]
                }
            ],
            "up": []
        },
        "kp_5": {
            "down": [
                {
                    "type": ConfigItemType.Purchase,
                    "items": [
                        "ak47"
                    ]
                }
            ],
            "up": []
        },
        "f1": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "autobuy"
                }
            ],
            "up": []
        },
        "f2": {
            "down": [
                {
                    "type": ConfigItemType.Action,
                    "cmd": "rebuy"
                }
            ],
            "up": []
        }
    },
    "alias": {
        "/q": [
            {
                "type": ConfigItemType.Action,
                "cmd": "quit"
            }
        ],
        "4x3": [
            {
                "type": ConfigItemType.Setting,
                "settingType": SettingType.Textbox,
                "cmd": "mat_setvideomode",
                "value": "1280 1024 0"
            }
        ]
    },
    "oneTime": [
        {
            "type": ConfigItemType.Action,
            "cmd": "display_damage_on"
        },
        {
            "type": ConfigItemType.Setting,
            "settingType": SettingType.Dropdown,
            "cmd": "cl_autowepswitch",
            "value": 0
        },
        {
            "type": ConfigItemType.Setting,
            "settingType": SettingType.Dropdown,
            "cmd": "m_rawinput",
            "value": 0
        },
        {
            "type": ConfigItemType.Setting,
            "settingType": SettingType.Dropdown,
            "cmd": "m_customaccel",
            "value": 0
        }
    ],
    "store": {
        "volume": {
            "min": 0,
            "max": 1,
            "step": 0.25
        }
    }
})