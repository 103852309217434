import { createSelector } from 'reselect';
import { ConfigGenerator } from '../../../services/config-generator';
import { BindingContextType, isAction, isExtra, isPurchase, isSetting, StoredConfig } from './config-editor-slice';
import { createBindingContextHandlerOrNull } from './config-editor-store-helpers';
import { ApplicationState } from '../../store';
import { createSorter } from '../../../utils/createSorter';

export const getConfig = (state: ApplicationState) => state.configEditor.config;
const getEditor = (state: ApplicationState) => state.configEditor.editor;

export const getBindingContext = createSelector(
    [getEditor],
    state => state.binding
);

const getConfigHandler = createSelector(
    [getConfig, getBindingContext],
    (config, bindingContext) => createBindingContextHandlerOrNull(config, bindingContext)
)

export const getIsValidBindingContext = createSelector(
    [getConfigHandler],
    handler => !!handler
);

export const getSelectedConfigItems = createSelector(
    [getConfigHandler],
    (handler) => handler?.getItems() ?? []
);

export const getSecondaryItems = createSelector(
    [getConfig, getBindingContext],
    (config, bindingContext) => {
        if (bindingContext.type !== BindingContextType.Keyboard)
            return [];
        const handler = createBindingContextHandlerOrNull(config, {...bindingContext, isKeyDown: !bindingContext.isKeyDown});
        return handler?.getItems() ?? [];
    }
)


// search
export const getSearch = createSelector(
    [getEditor],
    editor => editor.search
)


// actions
const getCmd = (state: ApplicationState, cmd: string) => cmd;

export const makeGetIsActionSelected = () => createSelector(
    [getSelectedConfigItems, getCmd],
    (items, cmd) => items.filter(isAction).some(a => a.cmd === cmd)
)


// aliases
export const getAliases = createSelector(
    [getConfig],
    (config) => Object.keys(config.alias).sort()
)


// purchase
const getPurchaseConfigItem = createSelector(
    [getSelectedConfigItems],
    (items) => items.find(isPurchase)
)

export const getPurchaseItems = createSelector(
    [getPurchaseConfigItem],
    purchaseItem => purchaseItem?.items ?? []
)


// settings
export const makeGetSettingConfigItem = () => createSelector(
    [getSelectedConfigItems, getCmd],
    (items, cmd) => items.filter(isSetting).find(i => i.cmd === cmd)
)


// extra
export const getExtraItems = createSelector(
    [getSelectedConfigItems],
    (items) => items.filter(isExtra)
)

export const getVolumePayload = createSelector(
    [getConfig],
    (config) => config.store.volume
)


// keyboard
const getBinds = createSelector(
    [getConfig],
    config => config.bind
)

export const getKeysSequences = createSelector(
    [getBinds],
    binds => {
        const sequences: string[][] = [];
        for (const objectKey of Object.keys(binds)) {
            const bind = binds[objectKey];
            if (bind.up.length || bind.down.length) {
                const sequence = objectKey.split(';');
                sequences.push(sequence);
            }
        }
        return sequences;
    }
)


// descriptor provider
const getTemplates = (state: ApplicationState) => state.templates;

export const getConfigGenerator = createSelector(
    [getTemplates],
    (templates) => new ConfigGenerator(templates)
)


// misc
var configSorter = createSorter<StoredConfig>(c => c.name);

export const getStoredConfigs = createSelector(
    [getEditor],
    (editor) => [...editor.storedConfigs].sort(configSorter)
)

export const getCurrentStoredConfig = createSelector(
    [getStoredConfigs, getEditor],
    (configs, editor) => configs.find(c => c.id === editor.cloudConfigId)
)

export const getShortLinks = createSelector(
    [getEditor],
    (editor) => editor.shortLinks
)

export const getConfigTab = createSelector(
    [getEditor],
    state => state.tab
)