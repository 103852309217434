import { _fetch } from "../../../services/sessionService";
import { AppThunk } from "../../store";

export enum CounterAction {
    OpenApp = 0,
    OpenConfig = 1,
    SaveConfig = 2,
    GenerateConfig = 3
}

export const counterActions = {
    hit: (action: CounterAction): AppThunk => async (dispatch, getState) => {
        await _fetch(`api/counter/${action}/hit`, 'POST');
    },
}