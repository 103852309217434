import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { configManagementActions } from "../../../store/features/config-management/config-management-logic";
import { configEditorActions, parseConfig } from "../../../store/features/config-editor/config-editor-logic";
import { getConfigGenerator } from "../../../store/features/config-editor/config-editor-selectors";
import ConfigEditor from "./ConfigEditor";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import JSZip from "jszip";
import { getIsAppInitialized } from "../../../store/features/app/app-selectors";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { StoredConfig } from "../../../store/features/config-editor/config-editor-slice";
import { routes } from "../../../routes";
import { downloadBlob } from "../../../utils/download";
import { useLoadingBar } from "../../../hooks/useLoadingBar";

const ShortLinkHandler = () => {

    const [configOptions, setConfigOptions] = useState<StoredConfig[]>([]);
    const startLoading = useLoadingBar();

    const dispatch = useAppDispatch();
    const history = useHistory();
    const { link } = useParams<{ link: string }>();
    const generator = useAppSelector(getConfigGenerator);
    const isInitialized = useAppSelector(getIsAppInitialized);

    const openMultiConfigPopup = useCallback((configs: StoredConfig[]) => {
        setConfigOptions(configs);
    }, []);

    const closeMultiConfigPopup = useCallback((configs: StoredConfig[]) => {
        setConfigOptions([]);
        history.push(routes.configEditor);
    }, [history]);

    const handleGenerateCfg = async (config: StoredConfig) => {
        dispatch(configEditorActions.setCurrentConfig(config.configJson, config.id));
        history.push(routes.generate);
    }

    const handleEdit = (config: StoredConfig) => {
        dispatch(configEditorActions.setCurrentConfig(config.configJson, config.id));
        history.push(routes.configEditor);
    }

    const handleZip = async () => {
        var zip = new JSZip();
        for (const entry of configOptions) {
            const parsed = parseConfig(entry.configJson);
            const text = generator.generate(parsed);
            zip.file(`${entry.name}.cfg`, text);
        }
        const blob = await zip.generateAsync({type: "blob"});
        downloadBlob(blob, "configs.zip");
    }

    useEffect(() => {
        if (!isInitialized) return; // waiting for templates to initialize

        startLoading(async () => {
            const MySwal = withReactContent(Swal);

            try {
                const configs = await dispatch(configManagementActions.fetchShortUrl(link));

                if (configs.length === 1) {
                    const config = configs[0];
                    dispatch(configEditorActions.setCurrentConfig(config.configJson, config.id));

                    const result = await MySwal.fire({
                        titleText: 'Success',
                        html: `Config <b>${config.name}</b> is loaded`,
                        icon: 'success',
                        showConfirmButton: true,
                        showCancelButton: true,
                        confirmButtonText: 'Generate',
                        cancelButtonText: 'Edit',
                    });

                    if (result.isConfirmed) {
                        history.push(routes.generate);
                    }
                    else {
                        history.push(routes.configEditor);
                    }
                }
                else {
                    openMultiConfigPopup(configs);
                }
            }
            catch (e) {
                await MySwal.fire('Oops', e.toString(), 'error');
                history.push(routes.configEditor);
            }
        });
    }, [history, isInitialized, openMultiConfigPopup, startLoading]);

    return (<>
        <ConfigEditor />
        <Modal show={!!configOptions.length} onHide={closeMultiConfigPopup}>
            <Modal.Header closeButton>
                <Modal.Title as="h5">{link}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {configOptions.map(c => (
                    <Form.Group>
                        <Row key={c.name} className='d-flex align-items-center'>
                            <Col xs={6} className='text-center'>
                                <h5>
                                    {c.name}.cpc
                                </h5>
                            </Col>
                            <Col xs={6}>
                                <Button variant='outline-primary' className='mr-1' onClick={() => handleGenerateCfg(c)}>Generate .cfg</Button>
                                <Button variant='outline-secondary' className='mr-1' onClick={() => handleEdit(c)}>Edit</Button>
                            </Col>
                        </Row>
                    </Form.Group>
                ))}
                <Form.Group className='d-flex justify-content-center'>
                    or just
                </Form.Group>
                <Form.Group className='d-flex justify-content-center'>
                    <Button variant='outline-primary' onClick={handleZip}>Download ZIP</Button>
                </Form.Group>
            </Modal.Body>
        </Modal>
    </>)
}

export default ShortLinkHandler;