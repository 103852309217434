import { createSelector } from 'reselect';
import { createTaskSelector } from '../task/tasks-selectors';
import { TaskStatus } from '../task/tasks-slice';
import { TaskEnum } from '../task/tasks-logic';

export const getIsStoredConfigListLoading = createSelector(
    [createTaskSelector(TaskEnum.loadConfigs)],
    task => !!task && task.status !== TaskStatus.Success
);

export const getIsStoredConfigListLoaded = createSelector(
    [createTaskSelector(TaskEnum.loadConfigs)],
    task => task?.status === TaskStatus.Success
);

export const getIsShortLinkListLoading = createSelector(
    [createTaskSelector(TaskEnum.loadShortUrls)],
    task => !!task && task.status !== TaskStatus.Success
);