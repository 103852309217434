import { useMemo } from "react";
import { Form } from "react-bootstrap";
import { localizeBuyItem } from "../../../locale/localization";
import { configEditorActions } from "../../../store/features/config-editor/config-editor-logic";
import { getIsValidBindingContext, getPurchaseItems } from "../../../store/features/config-editor/config-editor-selectors";
import { ConfigItemType, PurchaseItem } from "../../../store/features/config-editor/config-editor-slice";
import { getPurchaseCategories } from "../../../store/features/templates/templates-selectors";
import { useAppDispatch, useAppSelector } from "../../../store/store";

interface Props {
}

const colorT = '#D1C34A';
const colorCt = '#65BCE9';

const PurchaseTab = ({}: Props) => {

    const dispatch = useAppDispatch();

    const isValidBindingContext = useAppSelector(state => getIsValidBindingContext(state));
    const categories = useAppSelector(state => getPurchaseCategories(state));
    const selectedItems = useAppSelector(state => getPurchaseItems(state));

    const currentItem = useMemo<PurchaseItem>(() => {
        return {
            type: ConfigItemType.Purchase,
            items: selectedItems
        }
    }, [selectedItems]);

    const handleItemClick = (name: string) => {
        const items = selectedItems.filter(i => i !== name);
        if (items.length === selectedItems.length) {
            items.push(name);
        }

        if (items.length) {
            dispatch(configEditorActions.updateItem({ ...currentItem, items }));
        }
        else {
            dispatch(configEditorActions.toggleItem(currentItem));
        }
    }

    const {totalCt, totalT} = useMemo(() => {
        let totalT = 0;
        let totalCt = 0;

        for (const category of categories) {
            for (const item of category.items) {
                if (selectedItems.includes(item.name)) {
                    totalT += item.priceT;
                    totalCt += item.priceCt;
                }
            }
        }

        return {
            totalT,
            totalCt,
        }
    }, [categories, selectedItems]);

    return (
        <div>
            <Form.Group style={{fontSize: '1.1em'}}>
                TOTAL PRICE:{' '}
                <span style={{color: colorT}}>
                    <b>{totalT}$</b> T
                </span>,{' '}
                <span style={{color: colorCt}}>
                    <b>{totalCt}$</b> CT
                </span>
            </Form.Group>
            <Form.Group className='d-flex'>
                {categories.map(c => (
                    <div key={c.name} className='m-r-10'>
                        <b>
                            {c.name}
                        </b>
                        {c.items.map(i => (
                            <Form.Check
                                custom type='checkbox' id={i.name}
                                checked={selectedItems.includes(i.name)}
                                label={localizeBuyItem(i.name)}
                                disabled={!isValidBindingContext}
                                onChange={() => handleItemClick(i.name)}
                            />
                        ))}
                    </div>
                ))}
            </Form.Group>
        </div>
    )
}

export default PurchaseTab;