import { createIncrementor } from '../../../utils/createIncrementor';
import { Dispatch, GetState } from '../../store';
import { configEditorActions } from '../config-editor/config-editor-logic';
import { createTaskSelector } from './tasks-selectors';
import { createTask, Task, TaskStatus, updateTask } from './tasks-slice';

export enum TaskEnum {
    initAppPrimary = "initAppPrimary",
    initAppSecondary = "initAppSecondary",
    awakeServer = "awakeServer",
    loadConfigs = "loadConfigs",
    loadShortUrls = "loadShortUrls",
};

interface TaskOptions {
    dispatch: Dispatch;
    getState: GetState;
    type: TaskEnum;
    objectId?: any;
    skipIfSucceeded?: boolean;
}

const getId = createIncrementor();

export async function run(options: TaskOptions, fun: () => Promise<void>): Promise<void> {
    const { dispatch, getState, type, objectId, skipIfSucceeded } = options;

    const state = getState();
    const existingTask = createTaskSelector(type, objectId)(state);
    const isRunning = existingTask?.status === TaskStatus.Running;
    const isSuccess = existingTask?.status === TaskStatus.Success;

    if (isRunning || (skipIfSucceeded && isSuccess)) {
        return;
    }
    let task: Task = {
        id: getId(),
        type: type,
        objectId: objectId,
        status: TaskStatus.Running,
    }

    dispatch(createTask(task));
    let result = TaskStatus.Success;

    try {
        await fun();
    } catch {
        result = TaskStatus.Failed;
    }
    finally {
        task = {
            ...task,
            status: result,
        }
        dispatch(updateTask(task));
    }
}