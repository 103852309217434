import ConfigEditor from './App/components/ConfigEditor/ConfigEditor';
import SignUp1 from './App/layout/Sign/SignUp1';
import SignIn1 from './App/layout/Sign/SignIn1';
import CloudConfigs from './App/components/ConfigEditor/CloudConfigs';
import ShortLinks from './App/components/ConfigEditor/ShortLinks';
import ShortConfigHandler from './App/components/ConfigEditor/ShortConfigLoader';
import GenerateConfig from './App/components/ConfigEditor/GenerateConfig';
import AdminDashboard from './App/components/ConfigEditor/AdminDashboard';

export interface RouteObject {
    path: string;
    exact?: boolean;
    name?: string;
    component: any;
    isAdmin?: boolean;
}

const routes = {
    configEditor: '/',
    cloudConfigs: '/cloud',
    shortLinks: '/links',
    shortLink: (link: string) => `/short/${link}`,
    generate: '/generate',
    signup: '/signup',
    signin: '/signin',
    adminDashboard: '/admin-dashboard',
}

const adminRoutes: RouteObject[] = [
    { path: '/', exact: true, name: 'Editor', component: ConfigEditor },
    { path: routes.cloudConfigs, exact: true, name: 'Cloud', component: CloudConfigs },
    { path: routes.shortLinks, exact: true, name: 'Short links', component: ShortLinks },
    { path: routes.shortLink(':link'), exact: true, name: 'Short link', component: ShortConfigHandler },
    { path: routes.generate, exact: true, name: 'Generate', component: GenerateConfig },
    { path: routes.adminDashboard, exact: true, name: 'Admin dashboard', component: AdminDashboard, isAdmin: true },
];

const pageRoutes: RouteObject[] = [
    { path: routes.signup, exact: true, name: 'Signup', component: SignUp1 },
    { path: routes.signin, exact: true, name: 'Signin', component: SignIn1 },
];

export { pageRoutes, adminRoutes, routes };
