import { _fetch } from "../../../services/sessionService";
import { run, TaskEnum } from "../task/tasks-logic";
import { Config, receiveShortLinks, receiveStoredConfig, receiveStoredConfigs, ShortLink, StoredConfig } from "../config-editor/config-editor-slice";
import { AppThunk } from "../../store";
import { configEditorActions } from "../config-editor/config-editor-logic";

export const configManagementActions = {
    createConfig: (name: string, config: Config): AppThunk => async (dispatch, getState) => {
        const apiParams = {
            name,
            configJson: JSON.stringify(config)
        }
        const result = await _fetch<StoredConfig>(`api/config`, 'POST', apiParams);
        dispatch(receiveStoredConfig(result));
        dispatch(configEditorActions.setCurrentConfig(result.configJson, result.id));
    },
    updateConfig: (config: StoredConfig): AppThunk => async (dispatch, getState) => {
        const apiParams = {
            name: config.name,
            prevHash: config.hash,
            configJson: config.configJson,
        }
        const result = await _fetch<StoredConfig>(`api/config/${config.id}`, 'PUT', apiParams);
        dispatch(receiveStoredConfig(result));
    },
    fetchConfigs: (): AppThunk => async (dispatch, getState) => {
        await run({type: TaskEnum.loadConfigs, dispatch, getState}, async () => {
            const configs = await _fetch<StoredConfig[]>(`api/config`, 'GET');
            dispatch(receiveStoredConfigs(configs));
        });
    },
    deleteConfig: (config: StoredConfig): AppThunk => async (dispatch, getState) => {
        await _fetch(`api/config/${config.id}`, 'DELETE');
        await dispatch(configManagementActions.fetchConfigs());
        await dispatch(configManagementActions.fetchShortUrls());
    },
    createShortLink: (name: string, configNames: string[]): AppThunk => async (dispatch, getState) => {
        const apiParams = {
            name,
            configNames,
        }
        await _fetch(`api/shortlink`, 'POST', apiParams);
        await dispatch(configManagementActions.fetchShortUrls());
    },
    updateShortLink: (link: ShortLink): AppThunk => async (dispatch, getState) => {
        await _fetch(`api/shortlink`, 'PUT', link);
        await dispatch(configManagementActions.fetchShortUrls());
    },
    fetchShortUrls: (): AppThunk => async (dispatch, getState) => {
        await run({type: TaskEnum.loadShortUrls, dispatch, getState}, async () => {
            dispatch(receiveShortLinks([]));
            const configs = await _fetch<ShortLink[]>(`api/shortlink`, 'GET');
            dispatch(receiveShortLinks(configs));
        });
    },
    deleteShortLink: (link: ShortLink): AppThunk => async (dispatch, getState) => {
        await _fetch(`api/shortlink/${link.name}`, 'DELETE');
        await dispatch(configManagementActions.fetchShortUrls());
    },
    fetchShortUrl: (linkId: string): AppThunk<Promise<StoredConfig[]>> => async (dispatch, getState) => {
        return await _fetch<StoredConfig[]>(`api/shortlink/${linkId}`, 'GET');
    },
    createAnonymousLink: (configJson: string): AppThunk<Promise<string>> => async (dispatch, getState) => {
        const apiParams = {
            configJson,
        }
        return await _fetch<string>(`api/shortlink/anonymous`, 'POST', apiParams);
    },
}