export function downloadBlob(blob: Blob, name: string) {
    var a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = name;
    a.click();
}

export function downloadText(text: string, name: string) {
    var file = new Blob([text], {type: 'text/plain'});
    downloadBlob(file, name);
}

export function downloadJson(text: string, name: string) {
    var file = new Blob([text], {type: 'text/json'});
    downloadBlob(file, name);
}