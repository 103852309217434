import { Executable, AliasCmd, alias, bind } from "./executable-models";
import { executableVisitor } from "./executable-visitor";
import { ConfigItem, ConfigItemType, Config, BindingContext, BindingContextType, ActionItem, SettingItem, ExtraItemType, VolumeMode, isVolumeExtra, isExtra } from "../store/features/config-editor/config-editor-slice";
import { decodeSequence, encodeSequence } from "../utils/keySequenceUtils";
import { groupBy } from "../utils/groupBy";
import { TemplatesState, ActionTemplate, ActionCategory } from "../store/features/templates/templates-slice";

/*
    TODO:
    1. Handle keys when multiple commands on key down and single on key up
    2. Dont generate meta cmd with +/- prefixes when only single event is called
*/

export interface ConfigItemDescriptor {
    command: string;
    isMeta: boolean;
    dependency: Dependency | null;
}

export type DescriptorProvider = (item: ConfigItem, binding: BindingContext) => ConfigItemDescriptor;

interface Dependency {
    name: string;
    commands: Executable[];
}

export class ConfigGenerator {
    private builtInTemplates: TemplatesState;

    constructor(builtInTemplates: TemplatesState) {
        this.builtInTemplates = builtInTemplates;
    }

    public generate(config: Config) {
        const descriptorProvider = createDescriptorProvider(config, this.builtInTemplates);

        const stringify = (exec: Executable) => {
            return executableVisitor(exec);
        }
        const generateMetaScript = (name: string, keySequence: string[]) => {
            const onKeyDownBinding: BindingContext = {
                type: BindingContextType.Keyboard,
                keys: keySequence,
                isKeyDown: true
            }
            const onKeyUpBinding: BindingContext = {
                ...onKeyDownBinding,
                isKeyDown: false
            }
            const objKey = encodeSequence(keySequence);
            const binds = config.bind[objKey];
            const onKeyDown = binds?.down.map(i => descriptorProvider(i, onKeyDownBinding).command) ?? [];
            const onKeyUp = binds?.up.map(i => descriptorProvider(i, onKeyUpBinding).command) ?? [];

            const isMeta = (i: ConfigItem) => descriptorProvider(i, onKeyDownBinding).isMeta;

            const metaCommandsOnDown = (binds?.down ?? []).filter(isMeta);
            onKeyUp.push(...metaCommandsOnDown.map(i => descriptorProvider(i, onKeyUpBinding).command));

            return new MetaScript(name, onKeyDown, onKeyUp);
        }
        const getMetaScriptCommands = (metaScript: MetaScript, forStaticBind: boolean = false, saveMetaCommands: boolean = true): Executable[] | null => {
            if (
                metaScript.aliasOnKeyDown.commands.length === 0 &&
                metaScript.aliasOnKeyUp.commands.length === 0
            )
                return null;

            const isSingleSpacelessCmd = (
                metaScript.aliasOnKeyDown.commands.length === 1 &&
                !stringify(metaScript.aliasOnKeyDown.commands[0]).includes(' ') && (
                    metaScript.aliasOnKeyUp.commands.length === 0 || (
                        metaScript.aliasOnKeyUp.commands.length === 1 &&
                        stringify(metaScript.aliasOnKeyUp.commands[0]).startsWith('-')
                    )
                )
            );

            if (isSingleSpacelessCmd)
                return [metaScript.aliasOnKeyDown.commands[0]];

            const isKeyDownOnly = (
                metaScript.aliasOnKeyDown.commands.length > 0 &&
                metaScript.aliasOnKeyUp.commands.length === 0
            );

            if (isKeyDownOnly) {
                if (forStaticBind)
                    return metaScript.aliasOnKeyDown.commands;

                const alias: AliasCmd = {
                    ...metaScript.aliasOnKeyDown,
                    name: metaScript.name
                }

                if (saveMetaCommands) {
                    metaCommands.push(alias);
                }
                return [metaScript.name];
            }
            else {
                if (saveMetaCommands) {
                    metaCommands.push(metaScript.aliasOnKeyDown);
                    metaCommands.push(metaScript.aliasOnKeyUp);
                }
                return [metaScript.aliasOnKeyDown.name];
            }
        }


        const sequences: string[][] = [];
        const defaultBinds: Record<string, Executable> = {};

        for (const objKey of Object.keys(config.bind)) {
            const sequence = decodeSequence(objKey);
            sequences.push(sequence);
            for (const key of sequence) {
                defaultBinds[key] = `unbind ${key}`;
            }
        }

        // // ---- sequences processing ----
        const groupedSequences = groupBy(sequences, s => s[0]);

        const handleSequence = (key1: string, defaultsCollectingMode: boolean) => {
            const sequenceGroup = defaultsCollectingMode ? [] : groupedSequences[key1];

            const key1MetaScriptName = `meta_${key1}`;
            const key1MetaScript: MetaScript = generateMetaScript(key1MetaScriptName, [key1]);

            for (const sequenceEntry of sequenceGroup.filter(s => s.length === 2)) {
                const key2MetaScriptName = `meta_${sequenceEntry[0]}_${sequenceEntry[1]}`;
                const key2MetaScript: MetaScript = generateMetaScript(key2MetaScriptName, sequenceEntry);

                const key2Bind = getMetaScriptCommands(key2MetaScript);
                if (!key2Bind) continue;

                const onKey1DownKey2action: Executable = bind(sequenceEntry[1], key2Bind);
                const onKey1UpKey2action: Executable = defaultBinds[sequenceEntry[1]];

                key1MetaScript.aliasOnKeyDown.commands.push(onKey1DownKey2action);
                key1MetaScript.aliasOnKeyUp.commands.push(onKey1UpKey2action);
            }

            const isStaticKey = multiSequences.every(s => s[1] !== key1);
            const isStartKeyAnywhere = multiSequences.some(s => s[0] === key1);
            const saveMetaCommands = !defaultsCollectingMode || !isStartKeyAnywhere;

            const key1Bind = getMetaScriptCommands(key1MetaScript, isStaticKey, saveMetaCommands);
            if (!key1Bind) return;

            defaultBinds[key1] = bind(key1, key1Bind);
        }


        const monoSequences = sequences.filter(s => s.length === 1);
        const multiSequences = sequences.filter(s => s.length === 2);

        const monoSequenceStartKeys = monoSequences.map(s => s[0]);
        const multiSequenceStartKeys = multiSequences.map(s => s[0]);
        const multiSequencesStartKeySet = new Set<string>(multiSequenceStartKeys);

        const metaCommands: Executable[] = [];

        for (const startKey of monoSequenceStartKeys) {
            handleSequence(startKey, true);
        }
        for (const startKey of Array.from(multiSequencesStartKeySet)) {
            handleSequence(startKey, false);
        }

        const dependencies: Dependency[] = collectDependencies(config, descriptorProvider);

        if (metaCommands.length) {
            dependencies.push({
                name: 'meta commands',
                commands: metaCommands
            })
        }

        let configText = '';

        const appendLine = (line?: string) => {
            configText += (line ?? '') + '\r\n'
        }

        if (dependencies.some(d => d.commands.length)) {
            appendLine('// --- Dependencies ---');
            appendLine();

            for (const dependency of dependencies) {
                appendLine(`// ${dependency?.name}`);
                for (const exec of dependency.commands) {
                    appendLine(stringify(exec));
                }
                appendLine();
            }
        }

        if (config.oneTime.length) {
            appendLine('// --- One-time commands ---');
            const bindingCtx: BindingContext = { type: BindingContextType.OneTime };
            for (const item of config.oneTime) {
                const exec = descriptorProvider(item, bindingCtx).command;
                appendLine(stringify(exec));
            }
            appendLine();
        }

        if (sequences.length) {
            appendLine('// --- Binds ---');
            for (const key of Object.keys(defaultBinds)) {
                const exec = defaultBinds[key];
                appendLine(stringify(exec));
            }
            appendLine();
        }

        return configText;
    }
}

export function getSettingCommand(item: SettingItem): string {
    if (Array.isArray(item.value)) {
        return `toggle ${item.cmd} ${item.value.join(' ')}`;
    }
    else {
        return `${item.cmd} ${item.value}`
    }
}

function collectDependencies(config: Config, descriptorProvider: DescriptorProvider): Dependency[] {
    const dependencies: Dependency[] = [];
    const visitedItems = new Set<string>();
    let _isVolumeVisited = false;

    const isItemVisited = (cmd: string) => {
        const initialLength = visitedItems.size;
        visitedItems.add(cmd);
        return visitedItems.size === initialLength;
    }
    const isVolumeVisited = () => {
        const result = _isVolumeVisited;
        _isVolumeVisited = true;
        return result;
    }

    const handleItemDependencies = (item: ConfigItem, bindingCtx: BindingContext) => {
        const descriptor = descriptorProvider(item, bindingCtx);

        if (isItemVisited(descriptor.command) || !descriptor.dependency)
            return;

        if (isExtra(item) && isVolumeExtra(item)) {
            if (isVolumeVisited())
                return;
            dependencies.push(descriptor.dependency);
        }
        else {
            dependencies.push(descriptor.dependency);
        }
    }

    // binds
    for (const objKey of Object.keys(config.bind)) {
        const binds = config.bind[objKey];
        const sequence = decodeSequence(objKey);

        let bindingCtx: BindingContext = {
            type: BindingContextType.Keyboard,
            keys: sequence,
            isKeyDown: true
        }
        for (const item of binds.down) {
            handleItemDependencies(item, bindingCtx);
        }

        bindingCtx = {
            ...bindingCtx,
            isKeyDown: false
        }
        for (const item of binds.up) {
            handleItemDependencies(item, bindingCtx);
        }
    }

    // aliases
    const aliases: AliasCmd[] = [];
    for (const aliasName of Object.keys(config.alias)) {
        const bindingCtx: BindingContext = {
            type: BindingContextType.CustomAlias,
            alias: aliasName
        }
        const items = config.alias[aliasName];
        for (const item of items) {
            handleItemDependencies(item, bindingCtx);
        }
        const commands = items.map(i => descriptorProvider(i, bindingCtx).command);
        aliases.push(alias(aliasName, commands));
    }

    if (aliases.length > 0) {
        dependencies.push({
            name: 'Custom aliases',
            commands: aliases
        });
    }

    // one-time
    const bindingCtx: BindingContext = {
        type: BindingContextType.OneTime
    }
    for (const item of config.oneTime) {
        handleItemDependencies(item, bindingCtx);
    }

    return dependencies;
}

function createDescriptorProvider(config: Config, templates: TemplatesState): DescriptorProvider {
    const reducer = (prev: ActionTemplate[], cur: ActionCategory) => ([...prev, ...cur.items]);
    const actionTemplates = templates.actions.reduce(reducer, []);

    function getActionTemplate(item: ActionItem): ActionTemplate {
        const template = actionTemplates.find(t => t.cmd === item.cmd);
        if (!template)
            throw new Error(`Template was not found for action '${item.cmd}'`);
        return template;
    }
    function getBindingId(binding: BindingContext): string {
        switch (binding.type) {
            case BindingContextType.OneTime: {
                return 'onetime';
            }
            case BindingContextType.CustomAlias: {
                if (!binding.alias)
                    throw new Error('Alias name was expected');
                return `${binding.alias}_alias`;
            }
            case BindingContextType.Keyboard: {
                if (!binding.keys.length)
                    throw new Error('Key sequence was expected');
                return binding.keys.join('_') + `_${binding.isKeyDown ? 'down' : 'up'}`;
            }
            default: {
                const typeguard: never = binding;
                throw new Error(`Unknown binding: ${binding}`);
            }
        }
    }

    return (item, bindingContext) => {
        let bindingId = getBindingId(bindingContext);
        const postfix = bindingId.length ? `_${bindingId}` : '';

        switch (item.type) {
            case ConfigItemType.Action: {
                let command: string;
                const template = getActionTemplate(item);
                if (template.isMeta) {
                    if (bindingContext.type === BindingContextType.Keyboard)
                        command = bindingContext.isKeyDown ? `+${item.cmd}` : `-${item.cmd}`;
                    else
                        throw new Error('Meta commands can be bound only to keyboard');
                }
                else {
                    command = item.cmd;
                }

                let dependency: Dependency | null;
                if (template.dependencies) {
                    dependency = {
                        name: `'${item.cmd}' action`,
                        commands: template.dependencies
                    };
                }
                else {
                    dependency = null;
                }

                return {
                    command,
                    dependency,
                    isMeta: template.isMeta
                }
            }
            case ConfigItemType.Purchase: {
                const aliasName = `buy_${postfix}`;
                const aliasCmd: AliasCmd = alias(aliasName, item.items.map(i => {
                    const item = i.startsWith('flashbang') ? 'flashbang' : i;
                    return `buy ${item}`;
                }));

                const dependency = {
                    name: aliasName,
                    commands: [aliasCmd]
                }
                return {
                    command: aliasName,
                    dependency,
                    isMeta: false
                }
            }
            case ConfigItemType.Setting: {
                return {
                    command: getSettingCommand(item),
                    dependency: null,
                    isMeta: false
                }
            }
            case ConfigItemType.Extra: {
                switch (item.extraType) {
                    case ExtraItemType.ExecuteCmd: {
                        if (item.commands.length === 1) {
                            return {
                                command: item.commands[0],
                                dependency: null,
                                isMeta: false
                            }
                        }
                        else {
                            const aliasCmd: AliasCmd = alias(`execute${postfix}`, item.commands);
                            return {
                                command: aliasCmd.name,
                                dependency: {
                                    name: aliasCmd.name,
                                    commands: [aliasCmd]
                                },
                                isMeta: false
                            }
                        }
                    }
                    case ExtraItemType.LoopCommands: {
                        const cmd = `${bindingId}_loop`;
                        const lines: Executable[][] = [];

                        for (let i = 0; i < item.loop.length; i++)
                            lines.push([item.loop[i]]);

                        return {
                            command: cmd,
                            dependency: {
                                name: cmd,
                                commands: generateLoopDependencies(cmd, lines)
                            },
                            isMeta: false
                        }
                    }
                    case ExtraItemType.Volume: {
                        const volumePayload = config.store.volume;
                        if (!volumePayload)
                            throw new Error('Volume payload was expected');

                        const {min, max, step} = volumePayload;
                        let volumeValues: number[] = []

                        let currentValue = max;

                        while (currentValue >= min)
                        {
                            volumeValues.push(currentValue);
                            currentValue -= step;
                        }
                        if (volumeValues[volumeValues.length - 1] != min)
                            volumeValues.push(min);

                        volumeValues = volumeValues.reverse();

                        const volumeUpCmd = "volume_up";
                        const volumeDownCmd = "volume_down";

                        const iterationNames: string[] = volumeValues.map(v => `volume_${v}`);

                        const dependencies: Executable[] = [];

                        for (let i = 0; i < volumeValues.length; i++) {
                            const value = volumeValues[i];
                            const volumeCmd = `volume ${value}`;

                            const iterationCmds: Executable[] = [];

                            iterationCmds.push(volumeCmd);
                            iterationCmds.push(`echo ${volumeCmd}`);

                            if (i === 0)
                            {
                                iterationCmds.push(alias(volumeDownCmd, ['echo Volume: Min']));
                                iterationCmds.push(alias(volumeUpCmd, [iterationNames[i + 1]]));
                            }
                            else if (i === volumeValues.length - 1)
                            {
                                iterationCmds.push(alias(volumeUpCmd, ['echo Volume: Max']));
                                iterationCmds.push(alias(volumeDownCmd, [iterationNames[i - 1]]));
                            }
                            else
                            {
                                iterationCmds.push(alias(volumeDownCmd, [iterationNames[i - 1]]));
                                iterationCmds.push(alias(volumeUpCmd, [iterationNames[i + 1]]));
                            }

                            dependencies.push(alias(iterationNames[i], iterationCmds),);
                        }

                        dependencies.push(iterationNames[0]);

                        return {
                            command: item.mode === VolumeMode.Increase ? 'volume_up' : 'volume_down',
                            dependency: {
                                name: 'Volume regulator',
                                commands: dependencies
                            },
                            isMeta: false
                        }
                    }
                    default: {
                        const typeguard: never = item;
                        throw new Error(`Unknown extra item: ${JSON.stringify(typeguard)}`);
                    }
                }
            }
            default: {
                const typeguard: never = item;
                throw new Error(`Unknown config item: ${typeguard}`);
            }
        }
    };
}

class MetaScript {
    constructor(name: string, onKeyDown: Executable[], onKeyUp: Executable[]) {
        this.name = name;
        this.aliasOnKeyDown = alias(`+${name}`, onKeyDown);
        this.aliasOnKeyUp = alias(`-${name}`, onKeyUp);
    }

    public name: string;
    public aliasOnKeyDown: AliasCmd;
    public aliasOnKeyUp: AliasCmd;

    public getDependencies(): Executable[] {
        return [
            this.aliasOnKeyDown,
            this.aliasOnKeyUp
        ]
    }
}

function generateLoopDependencies(cmd: string, commandLines: Executable[][]): Executable[] {
    if (commandLines.length === 0)
        return []

    const cycleNames: string[] = [];

    for (let i = 0; i < commandLines.length; i++)
        cycleNames[i] = `${cmd}__${i}`;

    const dependencies: Executable[] = [];

    const headerAlias: AliasCmd = alias(cmd, [cycleNames[0]]);
    dependencies.push(headerAlias);

    for (let i = 0; i < commandLines.length; i++)
    {
        const currentBody = commandLines[i];

        const nextIterationName: string = i === commandLines.length - 1
            ? cycleNames[0]
            : cycleNames[i + 1];

        const transferAlias: AliasCmd = alias(cmd, [nextIterationName]);

        currentBody.push(transferAlias);

        dependencies.push(alias(cycleNames[i], currentBody));
    }

    return dependencies;
}