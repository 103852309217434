import { configureStore, ThunkAction, Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import ableReducer from './ableReducer';
import tasksReducer from './features/task/tasks-slice';
import templatesReducer from './features/templates/templates-slice';
import configReduder from './features/config-editor/config-editor-slice';
import userReducer from './features/user/user-slice';

export const store = configureStore({
  reducer: {
      able: ableReducer,
      tasks: tasksReducer,
      templates: templatesReducer,
      configEditor: configReduder,
      user: userReducer,
  },
});

type Store = typeof store;
export type GetState = Store["getState"]
export type Dispatch = ThunkDispatch<ApplicationState, any, Action>;
export type ApplicationState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = Promise<void> | void> = ThunkAction<ReturnType, ApplicationState, unknown, Action<string>>;

export const useAppDispatch = () => {
    return useDispatch<Dispatch>();
}

export const useAppSelector = <TSelected>(
  selector: (state: ApplicationState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
) => {
  return useSelector<ApplicationState, TSelected>(selector, equalityFn)
};