import { Suspense, useEffect, useMemo } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import '../../node_modules/font-awesome/scss/font-awesome.scss';
import Loader from './layout/Loader';
import ScrollToTop from './layout/ScrollToTop';
import { adminRoutes, pageRoutes, RouteObject } from '../routes';

import AdminLayout from './layout/AdminLayout/AdminLayout';
import { useAppDispatch, useAppSelector } from '../store/store';
import { appActions } from '../store/features/app/app-logic';
import { useLoadingBar } from '../hooks/useLoadingBar';
import { getUser } from '../store/features/user/user-selectors';

const App = () => {

    const dispatch = useAppDispatch();
    const location = useLocation();
    const startLoading = useLoadingBar();
    const user = useAppSelector(getUser);

    useEffect(() => {
        startLoading(dispatch(appActions.init()));
    }, [startLoading, dispatch]);

    const routes = useMemo<RouteObject[]>(() => {
        return user?.premium ? adminRoutes : adminRoutes.filter(r => !r.isAdmin);
    }, [user]);

    return (
        <>
            <ScrollToTop>
                <Suspense fallback={<Loader />}>
                    <Route path={pageRoutes.map((x) => x.path)}>
                        <Switch location={location} key={location.pathname}>
                            {pageRoutes.map((route, index) => {
                                return route.component ? (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        render={(props: any) => <route.component {...props} />}
                                    />
                                ) : null;
                            })}
                        </Switch>
                    </Route>
                    <Route exact path={routes.map((x) => x.path)}>
                        <AdminLayout />
                    </Route>
                </Suspense>
            </ScrollToTop>
            <div className="backdrop" />
        </>
    );
};
export default App;
