import * as React from "react";
import { useMemo } from "react";
import { Card } from "react-bootstrap";
import Chart from 'react-apexcharts';

interface Props {
    label: string;
    tooltip: string;
    total: number;
    series: number[];
}

type ChartProps = React.ComponentProps<typeof Chart>;

const SeriesCard = ({ label, tooltip, total, series }: Props) => {

    const chartData = useMemo<ChartProps>(() => {
        return {
            type: 'area',
            height: 50,
            options: {
                chart: {
                    sparkline: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                colors: ['#9ccc65'],
                fill: {
                    type: 'solid',
                    opacity: 0
                },
                grid: {
                    padding: {
                        left: 5,
                        right: 5
                    }
                },
                markers: {
                    size: 3,
                    opacity: 0.9,
                    colors: '#9ccc65',
                    strokeColor: '#9ccc65',
                    strokeWidth: 1,
                    hover: {
                        size: 4
                    }
                },
                stroke: {
                    curve: 'straight',
                    width: 2
                },
                tooltip: {
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: (seriesName: string) => tooltip,
                        },
                    },
                    marker: {
                        show: false
                    }
                }
            },
            series: [
                {
                    name: 'series1',
                    data: series,
                }
            ]
        };
    }, [series, tooltip]);

    return (
        <Card>
            <Card.Body>
                <h3>{total}</h3>
                <p className="text-muted">{label}</p>
                <Chart {...chartData} />
            </Card.Body>
        </Card>
    )
}

export default SeriesCard;