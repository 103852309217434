import english from "./english";

export const localizeAction = (cmd: string) => {
    const value = english[`action_${cmd}`];
    return value ?? cmd;
}

export const localizeBuyItem = (item: string) => {
    const value = english[`buyitem_${item}`];
    return value ?? item;
}

export const localizeTip = (tip: string) => {
    return english[`tip_${tip}`];
}