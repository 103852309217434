import { getActionCategories } from "../../../store/features/templates/templates-selectors";
import { useAppSelector } from "../../../store/store";
import ActionControl from "./ActionControl";

interface Props {
}

const ActionsTab = ({}: Props) => {

    const categories = useAppSelector(state => getActionCategories(state));

    return (
        <div>
            {categories.map(c => (
                <div key={c.name} className='float-left m-r-10'>
                    <div className='text-center'>
                        <b>{c.name}</b>
                    </div>
                    {c.items.map(action => (
                        <ActionControl key={action.cmd} action={action}/>
                    ))}
                </div>
            ))}
        </div>
    )
}

export default ActionsTab;