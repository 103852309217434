import * as React from "react";
import { useEffect, useMemo } from "react";
import { Button, Card } from "react-bootstrap";
import webConfigRepository from "../../../services/web-config-repository";
import { getIsAppInitialized } from "../../../store/features/app/app-selectors";
import { getConfig, getConfigGenerator, getCurrentStoredConfig } from "../../../store/features/config-editor/config-editor-selectors";
import { configManagementActions } from "../../../store/features/config-management/config-management-logic";
import { CounterAction, counterActions } from "../../../store/features/counter/counter-logic";
import { getUser } from "../../../store/features/user/user-selectors";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { useConfigNameProvider } from "../../../utils/withErrorAlert";
import Prism from "../Prism";

const GenerateConfig = () => {

    const dispatch = useAppDispatch();

    const generator = useAppSelector(getConfigGenerator);
    const config = useAppSelector(getConfig);
    const prevConfig = useAppSelector(getCurrentStoredConfig);
    const isInitialized = useAppSelector(getIsAppInitialized);
    const user = useAppSelector(getUser);

    const configNameProvider = useConfigNameProvider();
    
    const resultCfg = useMemo<string>(() => {
        return isInitialized ? generator.generate(config) : '';
    }, [isInitialized, generator, config]);

    const handleDownloadCfg = async () => {
        await configNameProvider(async (name: string) => {
            dispatch(counterActions.hit(CounterAction.GenerateConfig));
            await webConfigRepository.generateConfig(name, resultCfg);
        });
    }

    const handleDownloadCpc = async () => {
        await configNameProvider(async (name: string) => {
            dispatch(counterActions.hit(CounterAction.SaveConfig));
            await webConfigRepository.saveConfig(name, config);
        });
    }

    const handleUploadToCloud = async () => {
        await configNameProvider(async (name: string) => {
            dispatch(counterActions.hit(CounterAction.SaveConfig));
            if (!prevConfig) {
                await dispatch(configManagementActions.createConfig(name, config));
            } else {
                await dispatch(configManagementActions.updateConfig({
                    ...prevConfig,
                    name,
                    configJson: JSON.stringify(config)
                }));
            }
        });
    };
    
    return (
        <Card className="table-card">
            <Card.Header className='d-flex justify-content-between'>
                <Card.Title as="h5">Generated .CFG</Card.Title>
                <div>
                    <Button className="ml-1" size='sm' variant='outline-primary' onClick={handleDownloadCfg}>
                        Download .cfg
                    </Button>
                    <Button className="ml-1" size='sm' variant='outline-secondary' onClick={handleDownloadCpc}>
                        Download .cpc
                    </Button>
                    {user &&
                        <Button className="ml-1" size='sm' variant='outline-secondary' onClick={handleUploadToCloud}>
                            Upload to cloud
                        </Button>
                    }
                </div>
            </Card.Header>
            <Card.Body className="pb-0">
                <Prism code={resultCfg} language="jsx" />
            </Card.Body>
        </Card>
    )
}

export default GenerateConfig;