import { useCallback } from "react";
import Swal from "sweetalert2";
import withReactContent, { ReactSweetAlert, SweetAlert2 } from "sweetalert2-react-content";
import { getCurrentStoredConfig } from "../store/features/config-editor/config-editor-selectors";
import { useAppSelector } from "../store/store";

const withErrorAlert = async (fun: (swal: SweetAlert2 & ReactSweetAlert) => Promise<void>) => {
    const mySwal = withReactContent(Swal);
    try {
        await fun(mySwal);
    }
    catch (e) {
        await mySwal.fire('Oops', e.toString(), 'error');
    }
}

const useConfigNameProvider = () => {
    const prevConfig = useAppSelector(getCurrentStoredConfig);

    return useCallback(async (fun: (name: string) => Promise<void>) => {
        await withErrorAlert(async (swal) => {
            let configName: string;
    
            if (prevConfig) {
                configName = prevConfig.name;
            }
            else {
                configName = 'autoexec';
            }
    
            await fun(configName);
            await swal.fire('Success', ``, 'success');
        })
    }, [prevConfig]);
}

export { withErrorAlert, useConfigNameProvider };