import * as React from "react";
import { Button, Card, Form, Table } from "react-bootstrap";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { getStoredConfigs } from "../../../store/features/config-editor/config-editor-selectors";
import { StoredConfig } from "../../../store/features/config-editor/config-editor-slice";
import { configManagementActions } from "../../../store/features/config-management/config-management-logic";
import { configEditorActions } from "../../../store/features/config-editor/config-editor-logic";
import { useHistory } from "react-router";
import { routes } from "../../../routes";
import { createEmptyConfig } from "../../../services/config-editable-presets";
import { useState } from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const CloudConfigs = () => {

    const [editedConfig, setEditedConfig] = useState<StoredConfig | null>(null);

    const cloudConfigs = useAppSelector(getStoredConfigs);

    const dispatch = useAppDispatch();
    const history = useHistory();

    const handleAdd = async () => {
        const MySwal = withReactContent(Swal);

        const result = await MySwal.fire<string>({
            text: 'Config name:',
            input: 'text'
        });

        if (!result.value) {
            await MySwal.fire('Invalid name', '', 'error');
            return;
        }

        await dispatch(configManagementActions.createConfig(result.value, createEmptyConfig()));
        history.push(routes.configEditor);
    }

    const handleEdit = (c: StoredConfig) => {
        dispatch(configEditorActions.setCurrentConfig(c.configJson, c.id));
        history.push(routes.configEditor);
    }

    const handleDelete = async (c: StoredConfig) => {
        await dispatch(configManagementActions.deleteConfig(c));
    }

    const handleInputKeydown = async (e: any) => {
        if (e.key === 'Enter' && editedConfig) {
            await dispatch(configManagementActions.updateConfig(editedConfig));
            setEditedConfig(null);
        }
    }

    return (
        <Card className="table-card">
            <Card.Header className='d-flex justify-content-between'>
                <Card.Title as="h5">Cloud configs</Card.Title>
                <Button size='sm' variant='outline-primary' onClick={handleAdd}>
                    Add new
                </Button>
            </Card.Header>
            <Card.Body className="pb-0">
                <div style={{ height: '465px' }}>
                    <PerfectScrollbar>
                        <Table responsive hover>
                            <thead>
                                <tr>
                                    <th style={{ width: '80%' }}>Name</th>
                                    <th style={{ width: '20%' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {cloudConfigs.map(c => (
                                    <tr key={c.id}>
                                        <td>
                                            {editedConfig?.id === c.id ?
                                                <Form.Control
                                                    type='text' value={editedConfig.name} size='sm' style={{ width: `${editedConfig.name.length + 2}ch`}}
                                                    onChange={e => setEditedConfig({ ...editedConfig, name: e.target.value })} onKeyDown={handleInputKeydown}
                                                /> :
                                                <span role='button' onClick={() => { setEditedConfig(c); }}>
                                                    {c.name}
                                                </span>
                                            }
                                        </td>
                                        <td>
                                            <i className="icon feather icon-edit f-18 text-c-green" role='button' onClick={() => handleEdit(c)}/>
                                            <i className="feather icon-trash-2 f-18 ml-3 text-c-red" role='button' onClick={() => handleDelete(c)}/>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </PerfectScrollbar>
                </div>
            </Card.Body>
        </Card>
    )
}

export default CloudConfigs;