import { useState, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import useBindingContext from "../../../hooks/useBindingContext";
import { getAliases } from "../../../store/features/config-editor/config-editor-selectors";
import { BindingContextType } from "../../../store/features/config-editor/config-editor-slice";
import { configEditorActions } from "../../../store/features/config-editor/config-editor-logic";
import { Badge, Button, Col, Form, Row } from "react-bootstrap";

interface Props {
}

const Aliases = ({}: Props) => {

    const dispatch = useAppDispatch();

    const [input, setInput] = useState<string>('');
    const [bindingContext, setBindingContext] = useBindingContext();

    const aliases = useAppSelector(state => getAliases(state));

    const selectedAlias = useMemo<string | undefined>(() => {
        if (bindingContext.type === BindingContextType.CustomAlias)
            return bindingContext.alias;
        else
            return ''
    }, [bindingContext])

    const handleAdd = () => {
        dispatch(configEditorActions.addAlias(input));
        setBindingContext({type: BindingContextType.CustomAlias, alias: input})
    }

    const handleRemove = () => {
        setBindingContext({type: BindingContextType.CustomAlias, alias: undefined});
        selectedAlias && dispatch(configEditorActions.removeAlias(selectedAlias));
    }

    const handleAliasClick = (alias: string) => {
        setBindingContext({type: BindingContextType.CustomAlias, alias})
    }

    const allowAdd = input && aliases.every(a => a.trim().toLowerCase() !== input.trim().toLowerCase());

    return (
        <Row className='custom-aliases'>
            <Col xs={12} md={6}>
                <Form.Group>
                    <Form.Control type="text" placeholder="Add alias" value={input} onChange={e => setInput(e.target.value)}/>
                    <div className="form-icon">
                        <Button className="btn-icon" onClick={handleAdd} disabled={!allowAdd}>
                            <i className="feather icon-plus" />
                        </Button>
                    </div>
                </Form.Group>
            </Col>
            {!!selectedAlias &&
                <Col xs={12} md={6}>
                    <Button className='m-b-5' variant="danger" size='sm' onClick={handleRemove}>
                        Remove
                    </Button>
                </Col>
            }
            <Col xs={12}>
                {aliases.map(a => (
                    <Badge
                        className='m-r-5 m-b-5 f-20'
                        role='button'
                        key={a} variant={selectedAlias === a ? 'success' : 'primary'}
                        onClick={() => handleAliasClick(a)}
                    >
                        {a}
                    </Badge>
                ))}
            </Col>
        </Row>
    )
}

export default Aliases;