import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Executable } from "../../../services/executable-models";
import { GameEdition, SettingType } from "../config-editor/config-editor-slice";

export interface ActionCategory {
    name: string;
    items: ActionTemplate[];
}

export interface ActionTemplate {
    cmd: string;
    isMeta: boolean;
    dependencies?: Executable[];
    game?: GameEdition;
}

export interface PurchaseCategory {
    name: string;
    items: PurchaseTemplate[];
}

export interface PurchaseTemplate {
    name: string;
    priceCt: number;
    priceT: number;
}

export interface SettingsCategory {
    name: string;
    items: SettingTemplate[];
}

export type SettingTemplate = SliderSettingTemplate | DropdownSettingTemplate | TextboxSettingTemplate;

export interface AnySettingTemplate {
    type: SettingType;
    cmd: string;
}

export interface SliderSettingTemplate extends AnySettingTemplate {
    type: SettingType.Slider;
    default: number;
    min: number;
    max: number;
    step: number;
}

export interface DropdownSettingTemplate extends AnySettingTemplate {
    type: SettingType.Dropdown;
    options: string[] | number[];
    defaultIndex: number;
    displayNames?: Record<number | string, string>
}

export interface TextboxSettingTemplate extends AnySettingTemplate {
    type: SettingType.Textbox;
    defaultValue: string | number;
    isInteger?: boolean;
}

export interface TemplatesState {
    actions: ActionCategory[];
    purchases: PurchaseCategory[];
    settings: SettingsCategory[];
}

const initialState: TemplatesState = {
    actions: [],
    purchases: [],
    settings: [],
}

const templatesSlice = createSlice({
    name: 'templates',
    initialState,
    reducers: {
        receiveTemplates: (state, action: PayloadAction<TemplatesState>) => {
            state.actions = action.payload.actions;
            state.purchases = action.payload.purchases;
            state.settings = action.payload.settings;
        }
    }
});

export const { receiveTemplates } = templatesSlice.actions;
export default templatesSlice.reducer;