import React, { useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { getSettingCommand } from "../../../services/config-generator";
import { configEditorActions } from "../../../store/features/config-editor/config-editor-logic";
import { makeGetSettingConfigItem, getIsValidBindingContext } from "../../../store/features/config-editor/config-editor-selectors";
import { ConfigItemType, SettingItem, SettingType } from "../../../store/features/config-editor/config-editor-slice";
import { SettingTemplate } from "../../../store/features/templates/templates-slice";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import DropdownControl from "./DropdownControl";
import SliderControl from "./SliderControl";
import TextboxControl from "./TextboxControl";

interface Props {
    settingTemplate: SettingTemplate;
}

const SettingControl = ({ settingTemplate }: Props) => {

    const dispatch = useAppDispatch();

    const getSettingConfigItem = useMemo(makeGetSettingConfigItem, []);
    const isValidBinding = useAppSelector(state => getIsValidBindingContext(state));
    const settingItem = useAppSelector(state => getSettingConfigItem(state, settingTemplate.cmd));

    const normalizedItem = useMemo<SettingItem>(() => {
        switch (settingTemplate.type) {
            case SettingType.Slider: {
                if (settingItem && settingItem.settingType !== SettingType.Slider)
                    throw new Error('Slider setting was expected');
                return {
                    type: ConfigItemType.Setting,
                    settingType: SettingType.Slider,
                    cmd: settingTemplate.cmd,
                    value: settingItem?.value ?? settingTemplate.default
                }
            }
            case SettingType.Dropdown: {
                if (settingItem && settingItem.settingType !== SettingType.Dropdown)
                    throw new Error('Dropdown setting was expected');
                return {
                    type: ConfigItemType.Setting,
                    settingType: SettingType.Dropdown,
                    cmd: settingTemplate.cmd,
                    value: settingItem?.value ?? settingTemplate.options[settingTemplate.defaultIndex]
                }
            }
            case SettingType.Textbox: {
                if (settingItem && settingItem.settingType !== SettingType.Textbox)
                    throw new Error('Textbox setting was expected');
                return {
                    type: ConfigItemType.Setting,
                    settingType: SettingType.Textbox,
                    cmd: settingTemplate.cmd,
                    value: settingItem?.value ?? settingTemplate.defaultValue
                }
            }
            default: {
                const typeguard: never = settingTemplate;
                throw new Error(`Unknown template: ${typeguard}`);
            }
        }
    }, [settingTemplate, settingItem]);

    const settingCmd = useMemo<string>(() => {
        return getSettingCommand(normalizedItem);
    }, [normalizedItem]);

    const handleToggle = () => {
        dispatch(configEditorActions.toggleItem(normalizedItem));
    }

    const isSelected = !!settingItem;
    const disabled = !isValidBinding;

    return (
        <Row style={{ height: 35 }}>
            <Col xs={4} className='d-flex align-items-center'>
                <Form.Check
                    custom id={settingTemplate.cmd}
                    checked={isSelected} onChange={handleToggle}
                    type="checkbox" label={settingCmd} disabled={!isValidBinding}
                />
            </Col>
            <Col xs={7} className={`${normalizedItem.settingType}-control d-flex align-items-center justify-content-center`}>
                {settingTemplate.type === SettingType.Slider && normalizedItem.settingType === SettingType.Slider && (
                    <SliderControl template={settingTemplate} item={normalizedItem} disabled={disabled}/>
                )}
                {settingTemplate.type === SettingType.Dropdown && normalizedItem.settingType === SettingType.Dropdown && (
                    <DropdownControl template={settingTemplate} item={normalizedItem} disabled={disabled}/>
                )}
                {settingTemplate.type === SettingType.Textbox && normalizedItem.settingType === SettingType.Textbox && (
                    <TextboxControl template={settingTemplate} item={normalizedItem} isSelected={isSelected} disabled={disabled}/>
                )}
            </Col>
        </Row>
    )
}

export default SettingControl;