import React, { useState } from "react";
import { Badge, Button, Col, Form, Row } from "react-bootstrap";
import { configEditorActions } from "../../../store/features/config-editor/config-editor-logic";
import { getIsValidBindingContext } from "../../../store/features/config-editor/config-editor-selectors";
import { LoopCommandsExtraItem } from "../../../store/features/config-editor/config-editor-slice";
import { useAppDispatch, useAppSelector } from "../../../store/store"
import ExtraContainer from "./ExtraContainer";

interface Props {
    item: LoopCommandsExtraItem;
    isSelected: boolean;
}

const LoopCommandsExtra = ({ item, isSelected }: Props) => {

    const dispatch = useAppDispatch();

    const [input, setInput] = useState<string>('');
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

    const isValidBinding = useAppSelector(getIsValidBindingContext);

    const handleAdd = () => {
        dispatch(configEditorActions.updateItem({
            ...item,
            loop: [...item.loop, input.trim()],
        }));
        setInput('');
    }

    const handleKeyDown = (e: any) => { 
        if (e.key === 'Enter' && !!input) {
            handleAdd();
        }
    }

    const handleRemove = () => {
        if (selectedIndex === null)
            return;
        
        const loop = item.loop.slice(0, selectedIndex).concat(item.loop.slice(selectedIndex+1));

        if (loop.length > 0) {
            dispatch(configEditorActions.updateItem({
                ...item,
                loop: item.loop.slice(0, selectedIndex).concat(item.loop.slice(selectedIndex+1)),
            }));
        }
        else {
            dispatch(configEditorActions.toggleItem(item));
        }
    }

    return (
        <ExtraContainer item={item} isSelected={isSelected}>
            <Row>
                <Col xs={12} md={4}>
                    <Form.Group>
                        <Form.Control type="text" disabled={!isValidBinding} placeholder="Add command" value={input} onChange={e => setInput(e.target.value)} onKeyDown={handleKeyDown}/>
                        <div className="form-icon">
                            <Button className="btn-icon" onClick={handleAdd} disabled={!isValidBinding || !input.trim()}>
                                <i className="feather icon-plus" />
                            </Button>
                        </div>
                    </Form.Group>
                </Col>
                {selectedIndex !== null &&
                    <Col xs={12} md={4}>
                        <Button className='m-b-5' variant="danger" size='sm' onClick={handleRemove}>
                            Remove
                        </Button>
                    </Col>
                }
                <Col xs={12}>
                    {item.loop.map((c, i) => (
                        <Badge
                            className='m-r-5 m-b-5 f-20'
                            role='button'
                            key={i} variant={i === selectedIndex ? 'success' : 'primary'}
                            onClick={() => setSelectedIndex(i)}
                        >
                            {i + 1}. {c}
                        </Badge>
                    ))}
                </Col>
            </Row>
        </ExtraContainer>
    );
}

export default LoopCommandsExtra;