import { createSelector } from "reselect";
import { ApplicationState } from "../../store";

export const getState = (state: ApplicationState) => state.user;

export const getUser = createSelector(
    [getState],
    state => state.user
)

export const getIsPremium = createSelector(
    [getUser],
    user => !!user?.premium
)

export const getRequiredUser = createSelector(
    [getUser],
    (user) => {
        if (!user) {
            throw new Error("Authenticated user was expected");
        }
        return user;
    }
)

export const getIsDeveloper = createSelector(
    [getUser],
    (user) => user?.name == "exide"
)