import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum TaskStatus {
    Running,
    Success,
    Failed,
}

export interface Task {
    id: number;
    type: string;
    objectId: any;
    status: TaskStatus;
}

export interface TasksState {
    list: Task[];
}

const initialState: TasksState = {
    list: [],
}

const tasksSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        createTask: (state, {payload: task}: PayloadAction<Task>) => {
            state.list = [task, ...state.list];
        },
        updateTask: (state, {payload: task}: PayloadAction<Task>) => {
            state.list = state.list.map(t => t.id !== task.id ? t : task);
        }
    }
});

export const { createTask, updateTask } = tasksSlice.actions;
export default tasksSlice.reducer;