import React, { useState } from "react";
import { Badge, Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { configEditorActions } from "../../../store/features/config-editor/config-editor-logic";
import { getIsValidBindingContext } from "../../../store/features/config-editor/config-editor-selectors";
import { ConfigItem, ExecuteCmdExtraItem } from "../../../store/features/config-editor/config-editor-slice";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import ExtraContainer from "./ExtraContainer";

interface Props {
    item: ExecuteCmdExtraItem;
    isSelected: boolean;
}

const ExtraCmdContainer = ({ item, isSelected }: Props) => {

    const dispatch = useAppDispatch();

    const [input, setInput] = useState<string>('');
    const [selectedCmd, setSelectedCmd] = useState<string>();

    const isValidBinding = useAppSelector(state => getIsValidBindingContext(state));

    const updateItem = (item: ConfigItem) => {
        dispatch(configEditorActions.updateItem(item));
    }

    const handleAdd = () => {
        updateItem({
            ...item,
            commands: [
                ...item.commands,
                input
            ]
        });
        setInput('');
    }

    const handleRemove = () => {
        updateItem({
            ...item,
            commands: item.commands.filter(c => c !== selectedCmd)
        });
        setSelectedCmd(undefined);
    }

    const handleCmdClick = (cmd: string) => {
        setSelectedCmd(cmd);
    }

    const allowAdd = !!input && item.commands.every(c => c !== input);

    return (
        <ExtraContainer item={item} isSelected={isSelected}>
            <Row>
                <Col xs={12} md={4}>
                    <Form.Group>
                        <Form.Control type="text" disabled={!isValidBinding} placeholder="Add command" value={input} onChange={e => setInput(e.target.value)}/>
                        <div className="form-icon">
                            <Button className="btn-icon" onClick={handleAdd} disabled={!isValidBinding || !allowAdd}>
                                <i className="feather icon-plus" />
                            </Button>
                        </div>
                    </Form.Group>
                </Col>
                {!!selectedCmd &&
                    <Col xs={12} md={6}>
                        <Button className='m-b-5' variant="danger" size='sm' onClick={handleRemove}>
                            Remove
                        </Button>
                    </Col>
                }
                <Col xs={12}>
                    {item.commands.map(c => (
                        <Badge
                            className='m-r-5 m-b-5 f-20'
                            role='button'
                            key={c} variant={selectedCmd === c ? 'success' : 'primary'}
                            onClick={() => handleCmdClick(c)}
                        >
                            {c}
                        </Badge>
                    ))}
                </Col>
            </Row>
        </ExtraContainer>
    );
}

export default ExtraCmdContainer;