import { useMemo } from "react";
import { Form } from "react-bootstrap";
import { localizeAction } from "../../../locale/localization";
import { configEditorActions } from "../../../store/features/config-editor/config-editor-logic";
import { getBindingContext, getIsValidBindingContext, makeGetIsActionSelected } from "../../../store/features/config-editor/config-editor-selectors";
import { ActionItem, ConfigItemType, BindingContextType, GameEdition } from "../../../store/features/config-editor/config-editor-slice";
import { ActionTemplate } from "../../../store/features/templates/templates-slice";
import { useAppDispatch, useAppSelector } from "../../../store/store";

interface Props {
    action: ActionTemplate;
}

const ActionControl = ({action}: Props) => {

    const dispatch = useAppDispatch();
    const getIsActionSelected = useMemo(makeGetIsActionSelected, []);

    const isSelected = useAppSelector(state => getIsActionSelected(state, action.cmd));
    const isValidBinding = useAppSelector(state => getIsValidBindingContext(state));
    const bindingContext = useAppSelector(state => getBindingContext(state));

    const actionItem = useMemo<ActionItem>(() => {
        return {
            type: ConfigItemType.Action,
            cmd: action.cmd
        }
    }, [action]);

    const isEnabled = useMemo<boolean>(() => {
        if (!isValidBinding) {
            return false;
        }
        if (action.isMeta) {
            return (
                bindingContext.type === BindingContextType.Keyboard &&
                bindingContext.isKeyDown
            );
        }
        return true;
    }, [isValidBinding, bindingContext]);

    const onChange = () => {
        dispatch(configEditorActions.toggleItem(actionItem));
    }

    const prefix = action.isMeta ? "+" : "";
    // const tip = isEnabled ? prefix + action.cmd : undefined;
    const label = localizeAction(action.cmd);
    // const className =
    //     !isEnabled ? 'checkbox-disabled' :
    //     !isSelected ? 'checkbox-unchecked' :
    //     'checkbox-checked'

    return (
        <Form.Check
            custom type="checkbox" id={action.cmd}
            checked={isSelected} onChange={onChange}
            disabled={!isEnabled}
            label={<>{label} {renderGameEdition(action)}</>}
        />
    )
}

const renderGameEdition = (action: ActionTemplate): React.ReactNode | null => {
    if (action.game === GameEdition.CSGO)
        return <i style={{ color: '#696cdb' }}>CSGO</i>

    if (action.game === GameEdition.CS2)
        return <i style={{ color: '#e5a227' }}>CS2</i>

    return null;
}

export default ActionControl;
