import { useState, useEffect, useMemo } from "react";
import { BindingContextType, GameEdition } from "../../../store/features/config-editor/config-editor-slice";
import { useAppSelector } from "../../../store/store";
import { getKeysSequences } from "../../../store/features/config-editor/config-editor-selectors";
import { Button } from "react-bootstrap";
import useBindingContext from "../../../hooks/useBindingContext";
import english from "../../../locale/english";

interface Props {
    edition: GameEdition;
}

enum KeyState {
    Default,
    CurrentSequence,
    Bound1,
    Bound2,
}

const Keyboard = ({ edition }: Props) => {

    const cs2 = edition === GameEdition.CS2;

    const [bindingContext, setBindingContext] = useBindingContext();
    const sequences = useAppSelector(state => getKeysSequences(state));

    const [isSequence, setIsSequence] = useState<boolean>(false);

    const currentSequence = useMemo<string[]>(() => {
        return bindingContext.type === BindingContextType.Keyboard ? bindingContext.keys : [];
    }, [bindingContext]);

    const keyStates = useMemo<Record<string, KeyState>>(() => {
        const keyStates: Record<string, KeyState> = {};

        for (const sequence of sequences) {
            keyStates[sequence[0]] = KeyState.Bound1;
            if (currentSequence.length === 1 && sequence.length === 2) {
                if (currentSequence[0] === sequence[0]) {
                    keyStates[sequence[1]] = KeyState.Bound2;
                }
            }
        }

        for (const key of currentSequence) {
            keyStates[key] = KeyState.CurrentSequence;
        }
        return keyStates;
    }, [currentSequence, sequences]);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            e.shiftKey && setIsSequence(true);
        }
        const handleKeyUp = (e: KeyboardEvent) => {
            !e.shiftKey && setIsSequence(false);
        }
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        }
    }, []);

    const handleClick = (key: string) => {
        const isReset = currentSequence.length !== 1 || !isSequence;
        const newSequence = isReset ? [key] : [currentSequence[0], key];
        setBindingContext({ type: BindingContextType.Keyboard, keys: newSequence, isKeyDown: true });
    }

    // Components
    interface KeyProps {
        value: string;
        disabled?: boolean;
    }

    // ----------------- render -----------------
    const Key = ({ value, disabled }: KeyProps) => {
        const normalizedValue = value.toLowerCase();
        const keyState = keyStates[normalizedValue] ?? KeyState.Default;

        const isOutline = keyState === KeyState.Default || keyState === KeyState.Bound1;
        const color = (
            keyState === KeyState.CurrentSequence || keyState === KeyState.Bound1 ? 'primary' :
            keyState === KeyState.Bound2 ? 'info' :
            'secondary'
        );
        const variant = `${isOutline ? "outline-" : ""}${color}`

        const key = `virtual_key_${value}` as keyof typeof english;
        const label = english[key];
        console.log(key, label);

        return (
            <Button
                variant={variant}
                onClick={() => handleClick(normalizedValue)} disabled={disabled}
            >
                {label}
            </Button>
        )
    }

    const KbKey = ({ col = 2, ...rest }: { col?: number } & KeyProps) => {
        return (
            <div className='keyboard-key-wrapper' style={{ flexGrow: col }}>
                <Key {...rest} />
            </div>
        )
    }

    const Placeholder = ({ col = 1 }: { col?: number }) => {
        return <div style={{ flexGrow: col }} />
    }

    return (
        <div className='virtual-keyboard'>
            <div className='keyboard'>
                <div className='keyboard-row'>
                    <KbKey value='esc' disabled />
                    <Placeholder col={2} />
                    <KbKey value='f1' />
                    <KbKey value='f2' />
                    <KbKey value='f3' />
                    <KbKey value='f4' />
                    <Placeholder />
                    <KbKey value='f5' />
                    <KbKey value='f6' />
                    <KbKey value='f7' />
                    <KbKey value='f8' />
                    <Placeholder />
                    <KbKey value='f9' />
                    <KbKey value='f10' />
                    <KbKey value='f11' />
                    <KbKey value='f12' />
                    <Placeholder />
                    <KbKey value='prt' disabled />
                    <KbKey value='scrolllock' />
                    <KbKey value='pause' />
                    <Placeholder col={10} />
                </div>
                <div className='keyboard-row'>
                    <KbKey value='`' />
                    <KbKey value='1' />
                    <KbKey value='2' />
                    <KbKey value='3' />
                    <KbKey value='4' />
                    <KbKey value='5' />
                    <KbKey value='6' />
                    <KbKey value='7' />
                    <KbKey value='8' />
                    <KbKey value='9' />
                    <KbKey value='0' />
                    <KbKey value='-' />
                    <KbKey value='=' />
                    <KbKey value='backspace' col={4} />
                    <Placeholder />
                    <KbKey value='ins' />
                    <KbKey value='home' />
                    <KbKey value='pgpp' />
                    <Placeholder />
                    <KbKey value='numlock' />
                    <KbKey value={cs2 ? 'kp_divide' : 'kp_slash'} />
                    <KbKey value='kp_multiply' />
                    <KbKey value='kp_minus' />
                    <Placeholder />
                </div>
                <div className='keyboard-row'>
                    <KbKey value='tab' col={3} />
                    <KbKey value='q' />
                    <KbKey value='w' />
                    <KbKey value='e' />
                    <KbKey value='r' />
                    <KbKey value='t' />
                    <KbKey value='y' />
                    <KbKey value='u' />
                    <KbKey value='i' />
                    <KbKey value='o' />
                    <KbKey value='p' />
                    <KbKey value='[' />
                    <KbKey value=']' />
                    <KbKey value='\' col={3} />
                    <Placeholder />
                    <KbKey value='del' />
                    <KbKey value='end' />
                    <KbKey value='pgdn' />
                    <Placeholder />
                    <KbKey value={cs2 ? 'kp_7' : 'kp_home'} />
                    <KbKey value={cs2 ? 'kp_8' : 'kp_uparrow'} />
                    <KbKey value={cs2 ? 'kp_9' : 'kp_pgup'} />
                    <KbKey value='kp_plus' />
                    <Placeholder />
                </div>
                <div className='keyboard-row'>
                    <KbKey value='capsLock' col={4} />
                    <KbKey value='a' />
                    <KbKey value='s' />
                    <KbKey value='d' />
                    <KbKey value='f' />
                    <KbKey value='g' />
                    <KbKey value='h' />
                    <KbKey value='j' />
                    <KbKey value='k' />
                    <KbKey value='l' />
                    <KbKey value=';' disabled />
                    <KbKey value="'" disabled />
                    <KbKey value='enter' col={4} />
                    <Placeholder col={8} />
                    <KbKey value={cs2 ? 'kp_4' : 'kp_leftarrow'} />
                    <KbKey value='kp_5' />
                    <KbKey value={cs2 ? 'kp_6' : 'kp_rightarrow'} />
                    <Placeholder col={3} />
                </div>
                <div className='keyboard-row'>
                    <KbKey value='shift' col={5} />
                    <KbKey value='z' />
                    <KbKey value='x' />
                    <KbKey value='c' />
                    <KbKey value='v' />
                    <KbKey value='b' />
                    <KbKey value='n' />
                    <KbKey value='m' />
                    <KbKey value=',' />
                    <KbKey value='.' />
                    <KbKey value='/' />
                    <KbKey value='rshift' col={5} />
                    <Placeholder col={3} />
                    <KbKey value='uparrow' />
                    <Placeholder col={3} />
                    <KbKey value={cs2 ? 'kp_1' : 'kp_end'} />
                    <KbKey value={cs2 ? 'kp_2' : 'kp_downarrow'} />
                    <KbKey value={cs2 ? 'kp_3' : 'kp_pgdn'} />
                    <KbKey value='kp_enter' />
                    <Placeholder />
                </div>
                <div className='keyboard-row'>
                    <KbKey value='ctrl' col={3} />
                    <KbKey value='win' disabled />
                    <KbKey value='alt' col={3} />
                    <KbKey value='space' col={10} />
                    <KbKey value='ralt' col={3} />
                    <KbKey value='win' col={3} disabled />
                    <KbKey value='apps' col={3} disabled />
                    <KbKey value='rctrl' col={3} />
                    <Placeholder />
                    <KbKey value='leftarrow' />
                    <KbKey value='downarrow' />
                    <KbKey value='rightarrow' />
                    <Placeholder />
                    <KbKey value={cs2 ? 'kp_0' : 'kp_ins'} col={4}/>
                    <KbKey value='kp_del' />
                    <Placeholder col={3} />
                </div>
            </div>
            <div className='mouse'>
                <div className='mouse-nav-buttons'>
                    <div />
                    <Key value='mouse4' />
                    <Key value='mouse5' />
                    <div />
                </div>
                <div className='mouse-body'>
                    <div>
                        <div>
                            <Key value='mouse1' />
                        </div>
                        <div>
                            <Key value='mwheelup' />
                            <Key value='mouse3' />
                            <Key value='mwheeldown' />
                        </div>
                        <div>
                            <Key value='mouse2' />
                        </div>
                    </div>
                    <div />
                </div>
            </div>
        </div>
    )
}

export default Keyboard;