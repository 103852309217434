import { Executable, ExecutableType } from "./executable-models";

export function executableVisitor(exec: Executable | Executable[]): string {
    if (typeof exec === 'string') {
        return exec;
    }
    else if (!Array.isArray(exec)) {
        switch (exec.type) {
            case ExecutableType.AliasCmd: {
                return `alias ${exec.name} ${executableVisitor(exec.commands)}`;
            }
            case ExecutableType.BindCmd: {
                return `bind ${exec.key} ${executableVisitor(exec.commands)}`;
            }
            default: {
                const typeguard: never = exec;
                throw new Error(`Unknown executable: ${typeguard}`);
            }
        }
    }
    else {
        const strings = exec.map(e => executableVisitor(e));
        const joined = strings.join('; ');
        const needToWrap = strings.length !== 1 || strings[0].includes(' ');
        return needToWrap ? `"${joined}"` : joined;
    }
}