import { createSelector } from "reselect";
import { TaskEnum } from "../task/tasks-logic";
import { createTaskSelector } from "../task/tasks-selectors";
import { TaskStatus } from "../task/tasks-slice";

export const getIsAppPrimaryInitializing = createSelector(
    [createTaskSelector(TaskEnum.initAppPrimary)],
    (task) => task?.status !== TaskStatus.Success
)

const getIsAppSecondaryInitializing = createSelector(
    [createTaskSelector(TaskEnum.initAppSecondary)],
    (task) => task?.status !== TaskStatus.Success
)

export const getIsAppInitializing = createSelector(
    [getIsAppPrimaryInitializing, getIsAppSecondaryInitializing],
    (init1, init2) => init1 || init2
)

export const getIsAppInitialized = createSelector(
    [getIsAppInitializing],
    (initializing) => !initializing
)