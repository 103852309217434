import * as React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import './../../../assets/scss/style.scss';

import Breadcrumb from '../../../App/layout/AdminLayout/Breadcrumb';
import logoDark from '../../../assets/images/logo-dark.png';
import { useState } from 'react';
import { useAppDispatch } from '../../../store/store';
import { userActions } from '../../../store/features/user/user-logic';
import { routes } from '../../../routes';
const SignUp1 = () => {

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string | null>(null);

    const dispatch = useAppDispatch();
    const history = useHistory();

    const handleSubmit = async () => {
        try {
            await dispatch(userActions.register(email, name, password));
            history.push(routes.cloudConfigs);
        }
        catch (e) {
            setError(e.toString());
        }
    }
    
    return (
        <>
            <Breadcrumb />
            <div className="auth-wrapper">
                <div className="auth-content">
                    <div className="card">
                        <div className="row align-items-center text-center">
                            <div className="col-md-12">
                                <div className="card-body">
                                    {/* <img src={logoDark} alt="" className="img-fluid mb-4" /> */}
                                    <h4 className="mb-3 f-w-400">Sign up</h4>
                                    {error &&
                                        <span className='text-danger'>
                                            {error}
                                        </span>
                                    }
                                    <div className="form-group fill">
                                        <input
                                            value={name} onChange={e => setName(e.target.value)}
                                            type="text" className="form-control" id="Username" placeholder="Username"
                                        />
                                    </div>
                                    <div className="form-group fill">
                                        <input
                                            value={email} onChange={e => setEmail(e.target.value)}
                                            type="email" className="form-control" id="email" placeholder="Email Address"
                                        />
                                    </div>
                                    <div className="form-group fill mb-4">
                                        <input
                                            value={password} onChange={e => setPassword(e.target.value)}
                                            type="password" className="form-control" id="password" placeholder="Password"
                                        />
                                    </div>
                                    {/* <Form.Group className="text-left">
                                        <Form.Check
                                            custom
                                            type="checkbox"
                                            id="supported-checkbox"
                                            label={'Send me the Newsletter weekly.'}
                                        />
                                    </Form.Group> */}
                                    <button className="btn btn-primary btn-block mb-4" onClick={handleSubmit}>Sign up</button>
                                    <p className="mb-2">
                                        Already have an account?{' '}
                                        <NavLink to="/signin" className="f-w-400">
                                            Signin
                                        </NavLink>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default SignUp1;
