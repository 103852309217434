import sessionService, { _fetch, TokenResult } from "../../../services/sessionService";
import { AppThunk } from "../../store";
import { User, Claims, receiveUser } from "./user-slice";

interface LoginParams {
    login: string;
    password: string;
}

interface RegisterParams extends LoginParams {
    email: string;
}

export function extractUser(payload: Record<string, string> | null): User | null {
    if (!payload) return null;
    const id = parseInt(payload[Claims.UserId]);
    const name = payload[Claims.Name];
    const premium = payload[Claims.Premium].toLowerCase() == 'true';
    return { id, name, premium }
}

export const userActions = {
    initSessionService: (): AppThunk => async (dispatch, getState) => {
        await sessionService.initialize(
            refreshToken => `api/user/refresh?token=${refreshToken}`,
            payload => dispatch(receiveUser(extractUser(payload)))
        );
    },
    register: (email: string, login: string, password: string): AppThunk => async (dispatch, getState) => {
        const apiParams: RegisterParams = {
            email,
            login,
            password,
        }
        var response = await _fetch<TokenResult>(`api/user/register`, 'POST', apiParams);
        sessionService.storeTokens(response, false);
    },
    login: (login: string, password: string, rememberMe: boolean): AppThunk => async (dispatch, getState) => {
        const apiParams: LoginParams = {
            login,
            password,
        }
        var response = await _fetch<TokenResult>(`api/user/token`, 'POST', apiParams);
        sessionService.storeTokens(response, rememberMe);
    },
    logout: (): AppThunk => async (dispatch, getState) => {
        sessionService.storeTokens(null);
    },
}