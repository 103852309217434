import React, { useMemo, useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { configEditorActions } from "../../../store/features/config-editor/config-editor-logic";
import { TextboxSetting } from "../../../store/features/config-editor/config-editor-slice";
import { TextboxSettingTemplate } from "../../../store/features/templates/templates-slice";
import { useAppDispatch } from "../../../store/store";
import { validateIntString, validateFloatString } from "../../../utils/validate";

interface TextboxControlProps {
    template: TextboxSettingTemplate;
    item: TextboxSetting;
    isSelected: boolean;
    disabled?: boolean;
}

const TextboxControl = ({ template, item, isSelected, disabled }: TextboxControlProps) => {

    const dispatch = useAppDispatch();

    const normalizedValue = useMemo<string>(() => {
        return !Array.isArray(item.value) ? item.value.toString() : template.defaultValue.toString();
    }, [item, template]);

    const [input, setInput] = useState<string>(normalizedValue);

    const isNumeric = useMemo<boolean>(() => {
        return typeof template.defaultValue === 'number';
    }, [template]);

    const isValid = useMemo<boolean>(() => {
        if (isNumeric) {
            return template.isInteger
                ? validateIntString(input)
                : validateFloatString(input);
        }
        else {
            return true;
        }

    }, [input, isNumeric, template]);

    useEffect(() => {
        if (parseFloat(input) !== parseFloat(item.value.toString())) { // fix for input of '2.' for example
            setInput(item.value.toString());
        }
    }, [item]);

    useEffect(() => {
        if (!isSelected) {
            setInput(template.defaultValue.toString());
        }
    }, [isSelected, template])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const str: string = e.target.value;

        if (isNumeric) {
            if (template.isInteger && validateIntString(str)) {
                const value = parseInt(str);
                dispatch(configEditorActions.updateItem({ ...item, value }));
            }
            else if (validateFloatString(str)) {
                const value = parseFloat(str);
                dispatch(configEditorActions.updateItem({ ...item, value }));
            }
        }
        else {
            dispatch(configEditorActions.updateItem({ ...item, value: str }));
        }
        setInput(str);
    }

    return (
        <Form.Control size='sm' type='text' value={input} onChange={handleChange} disabled={disabled} isInvalid={!isValid} />
    )
}

export default TextboxControl;