import React, { useState, useMemo } from "react";
import { Dropdown, Form } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { configEditorActions, getDropdownSettingItemNames } from "../../../store/features/config-editor/config-editor-logic";
import { DropdownSetting } from "../../../store/features/config-editor/config-editor-slice";
import { DropdownSettingTemplate } from "../../../store/features/templates/templates-slice";
import { useAppDispatch } from "../../../store/store";
import { isArrayOfString, isArrayOfNumber } from "../../../utils/types";

interface SliderControlProps {
    template: DropdownSettingTemplate;
    item: DropdownSetting;
    disabled?: boolean;
}

const DropdownControl = ({ template, item, disabled }: SliderControlProps) => {

    const dispatch = useAppDispatch();

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const normalizedValue = useMemo<string | number>(() => {
        return !Array.isArray(item.value) ? item.value : template.options[template.defaultIndex];
    }, [item, template]);

    const isToggle = useMemo<boolean>(() => {
        const numberOptions = (template.options as any[]).map((o) => parseInt(o));
        return (
            numberOptions.length === 2 &&
            numberOptions.includes(0) &&
            numberOptions.includes(1)
        )
    }, [template]);

    const optionNames = useMemo(() => {
        return getDropdownSettingItemNames(template);
    }, [template]);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const handleDropdownChange = (value: string | number) => {
        dispatch(configEditorActions.updateItem({ ...item, value }));
    }

    const handleToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(configEditorActions.updateItem({ ...item, value: e.target.checked ? 1 : 0 }));
    }

    const DropdownSettingItem = ({ value }: { value: string | number }) => {
        return (
            <DropdownItem onClick={() => handleDropdownChange(value)}>
                {optionNames[value]}
            </DropdownItem>
        )
    }

    const rendereDropdown = () => {
        return (
            <Dropdown show={dropdownOpen} onToggle={toggle}>
                <DropdownToggle disabled={disabled} size='sm'>
                    {optionNames[normalizedValue]}
                </DropdownToggle>
                <DropdownMenu>
                    {isArrayOfString(template.options) && template.options.map(o => (
                        <DropdownSettingItem key={o} value={o} />
                    ))}
                    {isArrayOfNumber(template.options) && template.options.map(o => (
                        <DropdownSettingItem key={o} value={o} />
                    ))}
                </DropdownMenu>
            </Dropdown>
        )
    }

    const renderToggle = () => {
        return (<>
            <Form.Switch switch
                checked={item.value === 1} onChange={handleToggleChange}
                type="switch" id={`switch_${item.cmd}`} disabled={disabled}
            />
        </>)
    }

    return (<>
        {isToggle ? renderToggle() : rendereDropdown()}
    </>)
}

export default DropdownControl;