import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface User {
    id: number;
    name: string;
    premium: boolean;
}

export enum Claims {
    Name = "http://configpro.exideprod.com/claim/name",
    UserId = "http://configpro.exideprod.com/claim/user_id",
    Premium = "http://configpro.exideprod.com/claim/premium",
}

export interface UserState {
    user: User | null;
}

const initialState: UserState = {
    user: null,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        receiveUser: (state, action: PayloadAction<User | null>) => {
            state.user = action.payload;
        }
    }
});

export const { receiveUser } = userSlice.actions;
export default userSlice.reducer;