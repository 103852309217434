import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col } from "react-bootstrap";
import useBindingContext from "../../../hooks/useBindingContext";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { BindingContextType, ConfigItem, MoveDirection } from "../../../store/features/config-editor/config-editor-slice";
import { getIsValidBindingContext, getSecondaryItems, getSelectedConfigItems } from "../../../store/features/config-editor/config-editor-selectors";
import { getConfigItemName, configEditorActions } from "../../../store/features/config-editor/config-editor-logic";

interface Props {
    isCollapsed: boolean;
    onToggleCollapse: () => void;
}

const BindingItems = ({ isCollapsed, onToggleCollapse }: Props) => {

    const [bindingContext, setBindingContext] = useBindingContext();
    const primaryItems = useAppSelector(state => getSelectedConfigItems(state));
    const secondaryItems = useAppSelector(state => getSecondaryItems(state));
    const isValidBinding = useAppSelector(state => getIsValidBindingContext(state));

    const renderContent = () => {
        switch (bindingContext.type) {
            case BindingContextType.Keyboard: {
                const getHint = (keyState: "down" | "up") => {
                    const keys = bindingContext.keys;
                    if (keys.length === 1) return `Key "${keys[0]}" ${keyState === "down" ? 'press' : 'release'}`;
                    if (keys.length === 2) return `Key "${keys[1]}" ${keyState === "down" ? 'press' : 'release'} when key "${keys[0]}" is pressed`;
                    return '';
                }
                const handleClick = (keyState: "down" | "up") => {
                    setBindingContext({...bindingContext, isKeyDown: keyState === "down"});
                }
                const errorMessage = !isValidBinding ? 'Select key first' : undefined;
                return (
                    <>
                        {!errorMessage ? <>
                            <ItemGroup
                                items={bindingContext.isKeyDown ? primaryItems : secondaryItems}
                                active={isValidBinding && bindingContext.isKeyDown}
                                label={getHint("down")}
                                onFocusTab={() => handleClick("down")}
                                isCollapsed={isCollapsed}
                                onToggleCollapse={onToggleCollapse}
                                errorMessage={errorMessage}
                            />
                            <ItemGroup
                                items={!bindingContext.isKeyDown ? primaryItems : secondaryItems}
                                active={isValidBinding && !bindingContext.isKeyDown}
                                label={getHint("up")}
                                onFocusTab={() => handleClick("up")}
                                isCollapsed={isCollapsed}
                                onToggleCollapse={onToggleCollapse}
                                errorMessage={errorMessage}
                            />
                        </> : <>
                            <ItemGroup
                                items={primaryItems}
                                errorMessage={errorMessage}
                                single
                            />
                        </>}
                    </>
                )
            }
            case BindingContextType.OneTime: {
                return <ItemGroup items={primaryItems} isCollapsed={isCollapsed} onToggleCollapse={onToggleCollapse} active label='Settings' single/>
            }
            case BindingContextType.CustomAlias: {
                const errorMessage = !isValidBinding ? 'Select alias first' : undefined;
                return <ItemGroup items={primaryItems} isCollapsed={isCollapsed} onToggleCollapse={onToggleCollapse} active={isValidBinding} errorMessage={errorMessage} label={`Commands executed on typing '${bindingContext.alias}' in console`} single/>
            }
            default: {
                const typeguard: never = bindingContext;
                throw new Error(`Unknown binding context: ${typeguard}`)
            }
        }
    }

    return renderContent();
}

interface ItemGroupProps {
    items: ConfigItem[];
    label?: string;
    active?: boolean;
    errorMessage?: string;
    isCollapsed?: boolean;
    onFocusTab?: () => void;
    onToggleCollapse?: () => void;
    single?: boolean;
}

const ItemGroup = ({items, label, active, errorMessage, onFocusTab, isCollapsed, onToggleCollapse, single}: ItemGroupProps) => {

    const dispatch = useAppDispatch();
    const [selectedIndex, setSelectedIndex] = useState<number>();

    useEffect(() => {
        setSelectedIndex(undefined);
    }, [items.length]);

    const handleMove = (direction: MoveDirection) => {
        if (typeof selectedIndex === "number") {
            handleMoveItem?.(selectedIndex, direction);
            setSelectedIndex(direction === MoveDirection.Left
                ? selectedIndex - 1
                : selectedIndex + 1
            );
        }
    }

    const handleMoveItem = (index: number, direction: MoveDirection) => {
        dispatch(configEditorActions.moveItem(index, direction));
    }
    const handleFocusItem = (item: ConfigItem) => {
        dispatch(configEditorActions.focusItem(item));
    }

    const disabled = !!errorMessage;
    const allowMoveLeft = typeof selectedIndex === 'number' && selectedIndex > 0;
    const allowMoveRight = typeof selectedIndex === 'number' && selectedIndex < items.length - 1;
    const titleVariant = errorMessage ? 'danger' : (active && !single) ? 'success' : 'primary';

    return (
        <Col xs={12} md={single ? 12 : 6}>
            <Card>
                <Card.Header className='d-flex justify-content-between'>
                    <Card.Text className={`text-${titleVariant} m-b-0 f-20 f-w-600`} role='button' onClick={onToggleCollapse}>
                        {errorMessage ?? label}
                    </Card.Text>
                    <div>
                        {!errorMessage && <>
                            {active ? <>
                                <Button className='m-l-5' variant="outline-primary" size='sm' disabled={!allowMoveLeft} onClick={() => handleMove(MoveDirection.Left)}>
                                    {'<'}
                                </Button>
                                <Button className='m-l-5' variant="outline-primary" size='sm' disabled={!allowMoveRight} onClick={() => handleMove(MoveDirection.Right)}>
                                    {'>'}
                                </Button>
                            </> : <>
                                <Button className='m-l-5' variant="outline-primary" size='sm' onClick={onFocusTab}>
                                    Select
                                </Button>
                            </>}
                        </>}
                    </div>
                </Card.Header>
                {!isCollapsed && !disabled &&
                    <Card.Body>
                        {items.map((item, i) => {
                            const name = getConfigItemName(item);
                            const isSelected = i === selectedIndex;
                            return (
                                <Badge
                                    key={name} className='m-r-5 m-b-5 f-20'
                                    variant={isSelected ? "success" : "primary"}
                                    role='button'
                                    onClick={() => { onFocusTab?.(); setSelectedIndex(i); handleFocusItem?.(item)}}
                                >
                                    {name}
                                </Badge>
                            )
                        })}
                        {items.length === 0 &&
                            <Card.Text className="text-muted mb-1">
                                Nothing selected yet
                            </Card.Text>
                        }
                    </Card.Body>
                }
            </Card>
        </Col>
    )
}

export default BindingItems;