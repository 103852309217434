import React, { useMemo } from "react";
import { getSettingsCategories } from "../../../store/features/templates/templates-selectors";
import { SettingTemplate } from "../../../store/features/templates/templates-slice";
import { useAppSelector } from "../../../store/store";
import { Virtuoso } from 'react-virtuoso'
import SettingControl from "./SettingControl";

interface Props {
}

const SettingsTab = ({}: Props) => {

    const categories = useAppSelector(state => getSettingsCategories(state));
    
    const plainItems = useMemo<(string | SettingTemplate)[]>(() => {
        const items: (string | SettingTemplate)[] = [];
        for (const category of categories) {
            items.push(category.name);
            for (const template of category.items) {
                items.push(template);
            }
        }
        return items;
    }, [categories]);

    return (
        <>
            {plainItems.length > 0 && (
                <Virtuoso
                    style={{ height: '500px' }}
                    className='setting-tab'
                    totalCount={plainItems.length}
                    item={(index) => {
                        const item: string | SettingTemplate = plainItems[index];
                        if (typeof item === 'string') {
                            const className = categories[0].name !== item ? 'not-first-header' : '';
                            return (<>
                                <div className={`setting-category-header ${className}`}>
                                    {item}
                                </div>
                            </>)
                        }
                        else {
                            return (
                                <SettingControl key={item.cmd} settingTemplate={item}/>
                            )
                        } 
                    }}
                />
            )}
        </>
    )
}

export default SettingsTab;