import { useCallback, useEffect, useMemo } from "react";
import { routes } from "./routes";
import webConfigRepository from "./services/web-config-repository";
import { configEditorActions } from "./store/features/config-editor/config-editor-logic";
import { getConfig, getConfigGenerator, getCurrentStoredConfig, getStoredConfigs } from "./store/features/config-editor/config-editor-selectors";
import { receiveStoredConfigs } from "./store/features/config-editor/config-editor-slice";
import { configManagementActions } from "./store/features/config-management/config-management-logic";
import { CounterAction, counterActions } from "./store/features/counter/counter-logic";
import { userActions } from "./store/features/user/user-logic";
import { getIsDeveloper, getUser } from "./store/features/user/user-selectors";
import { useAppDispatch, useAppSelector } from "./store/store";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { createDefaultConfig, createEmptyConfig } from "./services/config-editable-presets";
import { useConfigNameProvider, withErrorAlert } from "./utils/withErrorAlert";
import { _BASE_URL_ } from "./consts";
import { useHistory } from "react-router";

interface Badge {
    title: string;
    type: string;
}
export interface MenuItemType {
    id: string;
    title: string;
    type: 'group' | 'collapse' | 'item';
    icon?: string;
    children?: MenuItemType[];
    breadcrumbs?: boolean;
    url?: string;
    badge?: Badge;
    target?: boolean;
    classes?: string;
    external?: boolean;
    onClick?: () => void;
}

export const useMenuItems = () => {

    const dispatch = useAppDispatch();
    const history = useHistory();

    const config = useAppSelector(state => getConfig(state));
    const generator = useAppSelector(state => getConfigGenerator(state));
    const prevConfig = useAppSelector(state => getCurrentStoredConfig(state));
    const user = useAppSelector(state => getUser(state));
    const configs = useAppSelector(state => getStoredConfigs(state));
    const isDeveloper = useAppSelector(getIsDeveloper);

    const configNameProvider = useConfigNameProvider();

    const handleDefaultConfig = useCallback(async () => {
        dispatch(configEditorActions.setCurrentConfig(JSON.stringify(createDefaultConfig()), null));
        const MySwal = withReactContent(Swal);
        await MySwal.fire('Success', 'Default config is loaded', 'success');
    }, [dispatch]);

    const handleEmptyConfig = useCallback(async () => {
        dispatch(configEditorActions.setCurrentConfig(JSON.stringify(createEmptyConfig()), null));
        const MySwal = withReactContent(Swal);
        await MySwal.fire('Success', 'Empty config is loaded', 'success');
    }, [dispatch]);

    const handleSelectFile = useCallback(async () => {
        const configResult = await webConfigRepository.openConfig();
        if (configResult) {
            const {configJson, name} = configResult;
            dispatch(configEditorActions.setCurrentConfig(configJson, null));
            dispatch(counterActions.hit(CounterAction.OpenConfig));
        }
    }, [dispatch]);

    const handleSaveToDrive = useCallback(async () => {
        await configNameProvider(async (name: string) => {
            dispatch(counterActions.hit(CounterAction.SaveConfig));
            await webConfigRepository.saveConfig(name, config);
        })
    }, [dispatch, configNameProvider, config]);

    const handleSaveToCloud = useCallback(async () => {
        await configNameProvider(async (name: string) => {
            dispatch(counterActions.hit(CounterAction.SaveConfig));
            if (!prevConfig) {
                await dispatch(configManagementActions.createConfig(name, config));
            } else {
                await dispatch(configManagementActions.updateConfig({
                    ...prevConfig,
                    name,
                    configJson: JSON.stringify(config)
                }));
            }
        });
    }, [dispatch, configNameProvider, config, prevConfig]);

    const handleSignout = useCallback(() => {
        dispatch(userActions.logout());
    }, [dispatch]);

    const handleCreateAnonymousLink = useCallback(async () => {
        await withErrorAlert(async (swal) => {
            const configJson = JSON.stringify(config);
            const defaultJson = JSON.stringify(createDefaultConfig());
            const emptyJson = JSON.stringify(createEmptyConfig());

            if (configJson === defaultJson) {
                throw 'We already have default config preset, change something at least :)';
            }
            else if (configJson === emptyJson) {
                throw 'We already have empty config preset, change something at least :)';
            }

            const guid = await dispatch(configManagementActions.createAnonymousLink(configJson));

            await navigator.clipboard.writeText(`${_BASE_URL_}${routes.shortLink(guid)}`);
            await swal.fire(
                `Success`,
                `ID: <b>${guid}</b><br/>` +
                `We've copied url to your clipboard.<br/>` +
                `It will be deleted after 30 days of idle.<br/>` +
                (!user
                    ? `<a href='${routes.signup}'>Signup</a> to create persistent links.`
                    : `Click <a href='${routes.shortLinks}'>here</a> to create persistent links.`
                ),
                'success'
            );
            history.push(routes.configEditor);
        });
    }, [dispatch, user, history, config]);

    useEffect(() => {
        if (user) {
            dispatch(configManagementActions.fetchConfigs());
            dispatch(configManagementActions.fetchShortUrls());
        }
        else {
            dispatch(receiveStoredConfigs([]));
        }
    }, [dispatch, user]);

    return useMemo<{ items: MenuItemType[] }>(() => {
        const adminSection: MenuItemType[] = [];
        const configSection: MenuItemType[] = [];
        const presetSection: MenuItemType[] = [];
        const cloudSection: MenuItemType[] = [];
        const fileSection: MenuItemType[] = [];
        const authSection: MenuItemType[] = [];

        if (user?.premium) {
            adminSection.push(
                {
                    id: 'admin-dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: routes.adminDashboard,
                    classes: 'nav-item',
                    icon: 'feather icon-activity'
                },
            );
        }

        configSection.push(
            {
                id: 'editor',
                title: 'Editor',
                type: 'item',
                url: routes.configEditor,
                classes: 'nav-item',
                icon: 'feather icon-edit'
            },
            {
                id: 'generate-config',
                title: 'Generate .cfg',
                type: 'item',
                url: routes.generate,
                classes: 'nav-item',
                icon: 'feather icon-file-text'
            },
            {
                id: 'anonymous-link',
                title: 'Share link',
                type: 'item',
                onClick: handleCreateAnonymousLink,
                classes: 'nav-item',
                icon: 'feather icon-share-2'
            },
        );

        presetSection.push(
            {
                id: 'default-config',
                title: 'Default config',
                type: 'item',
                onClick: handleDefaultConfig,
                classes: 'nav-item',
                icon: 'feather icon-sliders'
            },
            {
                id: 'empty-config',
                title: 'Clear config',
                type: 'item',
                onClick: handleEmptyConfig,
                classes: 'nav-item',
                icon: 'feather icon-sliders'
            },
        );

        if (user) {
            cloudSection.push(
                {
                    id: 'save-config-to-cloud',
                    title: 'Upload config',
                    type: 'item',
                    onClick: handleSaveToCloud,
                    classes: 'nav-item',
                    icon: 'feather icon-upload-cloud'
                },
                {
                    id: 'cloud-configs',
                    title: 'My cloud configs',
                    type: 'item',
                    url: routes.cloudConfigs,
                    classes: 'nav-item',
                    icon: 'feather icon-cloud'
                },
                {
                    id: 'short-links',
                    title: 'My short links',
                    type: 'item',
                    url: routes.shortLinks,
                    classes: 'nav-item',
                    icon: 'feather icon-link'
                },
            );
        }

        fileSection.push(
            {
                id: 'open-config',
                title: 'Open config [.cpc]',
                type: 'item',
                onClick: handleSelectFile,
                classes: 'nav-item',
                icon: 'feather icon-file-plus'
            },
            {
                id: 'save-config-to-drive',
                title: 'Save config [.cpc]',
                type: 'item',
                onClick: handleSaveToDrive,
                classes: 'nav-item',
                icon: 'feather icon-download'
            },
        );

        if (user) {
            authSection.push(
                {
                    id: 'signout',
                    title: 'Sign out',
                    type: 'item',
                    onClick: handleSignout,
                    icon: 'feather icon-log-out',
                }
            )
        }
        else {
            authSection.push(
                {
                    id: 'signin',
                    title: 'Sign in',
                    type: 'item',
                    url: routes.signin,
                    icon: 'feather icon-log-in',
                },
                {
                    id: 'signup',
                    title: 'Sign up',
                    type: 'item',
                    url: routes.signup,
                    icon: 'feather icon-lock',
                }
            );
        }

        const items: MenuItemType[] = [];

        if (adminSection.length) {
            items.push(
                {
                    id: 'admin-section',
                    title: 'Admin',
                    type: 'group',
                    icon: 'icon-support',
                    children: adminSection,
                },
            );
        }
        if (configSection.length) {
            items.push(
                {
                    id: 'config-section',
                    title: 'Config',
                    type: 'group',
                    icon: 'icon-support',
                    children: configSection,
                },
            );
        }
        if (presetSection.length) {
            items.push(
                {
                    id: 'presets-section',
                    title: 'Presets',
                    type: 'group',
                    icon: 'icon-support',
                    children: presetSection,
                },
            );
        }
        if (cloudSection.length) {
            items.push(
                {
                    id: 'cloud-section',
                    title: 'Cloud',
                    type: 'group',
                    icon: 'icon-support',
                    children: cloudSection
                },
            );
        }
        if (fileSection.length) {
            items.push(
                {
                    id: 'files-section',
                    title: 'File',
                    type: 'group',
                    icon: 'icon-support',
                    children: fileSection,
                },
            );
        }
        if (authSection.length) {
            items.push(
                {
                    id: 'auth-section',
                    title: 'Authentication',
                    type: 'group',
                    icon: 'icon-pages',
                    children: authSection,
                },
            )
        }


        return { items }
    }, [dispatch, history, handleSelectFile, handleSaveToDrive, handleSignout, handleDefaultConfig, handleEmptyConfig, handleCreateAnonymousLink, user, configs, isDeveloper]);
}
