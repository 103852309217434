import * as React from "react";
import { Card, Col, Row } from "react-bootstrap";

interface Props {
    icon: string;
    label: string;
    total: number;
    color?: string;
}

const StatsCard = ({ icon, label, total, color='green' }: Props) => {
    return (
        <Card>
            <Card.Body>
                <Row className="align-items-center m-l-0">
                    <Col sm="auto">
                        <i className={`icon feather icon-${icon} f-30 text-c-${color}`} />
                    </Col>
                    <Col>
                        <h6 className="text-muted m-b-10">{label}</h6>
                        <h2 className="m-b-0">{total}</h2>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default StatsCard;